/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { Common } from './common.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, integer, boolean, uri } from '../validators.js';

export class Resource extends Common {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('Resource', 'schemaName', data.schemaName);
                hasValue('Resource', 'schemaName', 'meta-resource', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-resource';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('Resource', 'payload', data.payload);
        this.payload = this.payload || {};
        string('Resource', 'payload.type', data.payload.type);
        oneOf('Resource', 'payload.type', ['navigation', 'resource'], data.payload.type);
        this.payload.type = data.payload.type;
        try {
            if (data.payload.firstMeaningfulPaint !== undefined) {
                integer('Resource', 'payload.firstMeaningfulPaint', data.payload.firstMeaningfulPaint);
                this.payload.firstMeaningfulPaint = parseInt(data.payload.firstMeaningfulPaint, 10);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.cachedContent !== undefined) {
                boolean('Resource', 'payload.cachedContent', data.payload.cachedContent);
                this.payload.cachedContent = typeof data.payload.cachedContent === 'string' ? data.payload.cachedContent === 'true' : data.payload.cachedContent;
            } else if (!isExtended) {
                this.payload.cachedContent = false;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.url !== undefined) {
                string('Resource', 'payload.url', data.payload.url);
                uri('Resource', 'payload.url', data.payload.url);
                this.payload.url = data.payload.url;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.nextHopProtocol !== undefined) {
                string('Resource', 'payload.nextHopProtocol', data.payload.nextHopProtocol);
                this.payload.nextHopProtocol = data.payload.nextHopProtocol;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.initiatorType !== undefined) {
                string('Resource', 'payload.initiatorType', data.payload.initiatorType);
                this.payload.initiatorType = data.payload.initiatorType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        Object.keys(data.payload).filter(key => /a_brws_prf_.*/.test(key)).forEach((key) => {
            const temp = data.payload[key];
            integer('Resource', 'temp', temp);
            this.payload[key] = temp;
        });
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
