/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, boolean, number, uri } from '../validators.js';

export class Video extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('Video', 'schemaName', data.schemaName);
                hasValue('Video', 'schemaName', 'meta-video', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-video';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('Video', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('Video', 'payload.type', data.payload.type);
                hasValue('Video', 'payload.type', 'Video', data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'Video';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.contentId !== undefined) {
                string('Video', 'payload.contentId', data.payload.contentId);
                this.payload.contentId = data.payload.contentId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.playerId !== undefined) {
                string('Video', 'payload.playerId', data.payload.playerId);
                this.payload.playerId = data.payload.playerId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.title !== undefined) {
                string('Video', 'payload.title', data.payload.title);
                this.payload.title = data.payload.title;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.description !== undefined) {
                string('Video', 'payload.description', data.payload.description);
                this.payload.description = data.payload.description;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.category !== undefined) {
                string('Video', 'payload.category', data.payload.category);
                this.payload.category = data.payload.category;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.autoplay !== undefined) {
                boolean('Video', 'payload.autoplay', data.payload.autoplay);
                this.payload.autoplay = typeof data.payload.autoplay === 'string' ? data.payload.autoplay === 'true' : data.payload.autoplay;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.autopause !== undefined) {
                boolean('Video', 'payload.autopause', data.payload.autopause);
                this.payload.autopause = typeof data.payload.autopause === 'string' ? data.payload.autopause === 'true' : data.payload.autopause;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.live !== undefined) {
                boolean('Video', 'payload.live', data.payload.live);
                this.payload.live = typeof data.payload.live === 'string' ? data.payload.live === 'true' : data.payload.live;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.length !== undefined) {
                number('Video', 'payload.length', data.payload.length);
                this.payload.length = parseFloat(data.payload.length);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.startTime !== undefined) {
                number('Video', 'payload.startTime', data.payload.startTime);
                this.payload.startTime = parseFloat(data.payload.startTime);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('Video', 'payload.src', data.payload.src);
        uri('Video', 'payload.src', data.payload.src);
        this.payload.src = data.payload.src;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
