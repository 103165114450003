import { storePromise } from "../shared/state/store/promise";

let listeners = [];
let complete = false;

async function domComplete() {
    const store = await storePromise();
    complete = true;
    store.dispatch('setStartedDomLogging', true);
    document.removeEventListener('DOMContentLoaded', domComplete);
    window.removeEventListener('load', domComplete);
    listeners.forEach(func => func());
    listeners = [];
}

export function ready(func: () => void) {
    if (complete) {
        func();
    } else {
        listeners.push(func);
    }
}

export const initiateDom = () => {

    if (document.readyState !== 'loading') {
        window.setTimeout(domComplete, 0);
    } else {
        document.addEventListener('DOMContentLoaded', domComplete);
        window.addEventListener('load', domComplete);
    }
    
}