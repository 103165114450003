/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, uri, integer } from '../validators.js';

export class ClickEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('ClickEvent', 'schemaName', data.schemaName);
                hasValue('ClickEvent', 'schemaName', 'event-click', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-click';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('ClickEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.link !== undefined) {
                string('ClickEvent', 'payload.link', data.payload.link);
                uri('ClickEvent', 'payload.link', data.payload.link);
                this.payload.link = data.payload.link;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('ClickEvent', 'payload.cssSelector', data.payload.cssSelector);
        this.payload.cssSelector = data.payload.cssSelector;
        integer('ClickEvent', 'payload.coordinateX', data.payload.coordinateX);
        this.payload.coordinateX = parseInt(data.payload.coordinateX, 10);
        integer('ClickEvent', 'payload.coordinateY', data.payload.coordinateY);
        this.payload.coordinateY = parseInt(data.payload.coordinateY, 10);
        string('ClickEvent', 'payload.type', data.payload.type);
        hasValue('ClickEvent', 'payload.type', 'click', data.payload.type);
        this.payload.type = data.payload.type;
        try {
            if (data.payload.clickLabel !== undefined) {
                string('ClickEvent', 'payload.clickLabel', data.payload.clickLabel);
                this.payload.clickLabel = data.payload.clickLabel;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.clickValue !== undefined) {
                string('ClickEvent', 'payload.clickValue', data.payload.clickValue);
                this.payload.clickValue = data.payload.clickValue;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
