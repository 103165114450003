
let passiveSupported = false;

try {
    const options = {
        get passive() {
            passiveSupported = true;
            return false;
        }
    };
    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, {});
} catch (err) {
    passiveSupported = false;
}

export function addEvent(target, type, listener, capture = false) {
    let options: boolean | {passive: boolean, capture: boolean}= false;
    if (passiveSupported) {
        options = {
            passive: true,
            capture
        };
    }
    target.addEventListener(type, listener, options);
}
