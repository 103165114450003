/**
 * This module exposes a logError method, wraps console.error
 * and logs all global errors to the server.
 * @module /lib/events/error/error
 * @typicalname error
 */

import { ConstErrorData, ErrorData } from "../../../../declarations/errors";
import { getRoot, log } from "../../../shared/logger";
import { createError } from "../../../shared/utils";
import { version } from "../../../version";
import { parseErrorStack } from "./stack-parser";

/**
 * Log an Error event to the server
 * @param { Object } data The Error Event payload
 * @param { HTMLElement } [element] An optional HTML element assosiated with the error
 */

let lastData: ErrorData;
let lastElement: HTMLElement;

export function logError(data: ErrorData, element: HTMLElement, trace = "") {
  if ((data === lastData && element === lastElement)) {
    return;
  }
  if (typeof data === "string") {
    throw new Error(
      `"logError" failed. Argument must be an object of type ${Object.keys(
        ConstErrorData
      )}`
    );
  }
  if (typeof data !== "object") {
    throw new Error(
      '"logError" failed. Argument must be an object or a string'
    );
  }
  lastData = data;
  lastElement = element;
  data.stacktrace = parseErrorStack(data);
  if (data.message === "" && data.stacktrace.length === 0) {
    return Promise.resolve();
  }

  if (data.message.toString().substr(0, 2) !== "V:") {
    data.message = "E:" + version + " " + data.message;
  }

  return log<"ErrorEvent">("ErrorEvent", data, element || getRoot()).catch(
    () => {}
  );
}

export const initErrorLogTracker = () => {
  self.addEventListener("error", (e: ErrorEvent) => {
    if (e.error) {
      const error = createError(e.error);
      logError(error, null);
    }
  });

  self.addEventListener("unhandledrejection", (e) => {
    if (e.reason) {
      const error = createError(e.reason);
      logError(error, null);
    }
  });
  /**
   * The argument to send to the console.error method
   * can be either a string or an Error. If its a string the
   * value will be the `message` property in the error
   * passed to the server
   * @typedef {string|Error} consoleErrorArgument
   */
  /**
   * The console.error method is monkey patched to log both to the console
   * and to the server if the first argument is a string or an Error Object.
   * @typedef {object} console
   * @property {function(consoleErrorArgument)} console.error - wrapped console error method
   * @global
   */
  if (console && console.error) {
    const consoleError = console.error;
    console.error = function (...args) {
      const error =
        typeof args[0] === "string"
          ? createError(args[0])
          : args[0] instanceof Error
          ? createError(args[0].message)
          : null;
      if (typeof args[0] === "string" || args[0] instanceof Error) {
        logError(error, null, args[1] ?? "");
      }
      consoleError.apply(console, args);
    };
  }
};

// This was removed to test if we will miss errors
//ready(initErrorLogTracker);
