import { actions, mutations } from "./store/methods";
import state from "./store/state";
import Store from "./store/store";

export default new Promise((res, rej) => {
  window.addEventListener("adplogger:store-init", (evt: CustomEvent) => {
    if (evt.detail) {
      res(evt.detail);
    }
  });
  setTimeout(() => {
    res(
      new Store({
        actions,
        mutations,
        state,
      })
    );
  }, 500);
}) as Promise<Store>;
