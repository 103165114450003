/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf } from '../validators.js';

export class CommentEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('CommentEvent', 'schemaName', data.schemaName);
                hasValue('CommentEvent', 'schemaName', 'event-comment', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-comment';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('CommentEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('CommentEvent', 'payload.type', data.payload.type);
        hasValue('CommentEvent', 'payload.type', 'comment', data.payload.type);
        this.payload.type = data.payload.type;
        string('CommentEvent', 'payload.action', data.payload.action);
        oneOf('CommentEvent', 'payload.action', ['created', 'edited', 'deleted'], data.payload.action);
        this.payload.action = data.payload.action;
        string('CommentEvent', 'payload.messageId', data.payload.messageId);
        this.payload.messageId = data.payload.messageId;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
