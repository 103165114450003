/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, number } from '../validators.js';

export class PlaybackAdEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('PlaybackAdEvent', 'schemaName', data.schemaName);
                hasValue('PlaybackAdEvent', 'schemaName', 'event-playback-ad', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-playback-ad';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('PlaybackAdEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.adpType !== undefined) {
                string('PlaybackAdEvent', 'payload.adpType', data.payload.adpType);
                oneOf('PlaybackAdEvent', 'payload.adpType', ['ad-started', 'ad-skipped', 'ad-blocked', 'ad-completed', 'ad-resumed', 'ad-paused'], data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adType !== undefined) {
                string('PlaybackAdEvent', 'payload.adType', data.payload.adType);
                this.payload.adType = data.payload.adType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adId !== undefined) {
                string('PlaybackAdEvent', 'payload.adId', data.payload.adId);
                this.payload.adId = data.payload.adId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.currentTime !== undefined) {
                number('PlaybackAdEvent', 'payload.currentTime', data.payload.currentTime);
                this.payload.currentTime = parseFloat(data.payload.currentTime);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.duration !== undefined) {
                number('PlaybackAdEvent', 'payload.duration', data.payload.duration);
                this.payload.duration = parseFloat(data.payload.duration);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.length !== undefined) {
                number('PlaybackAdEvent', 'payload.length', data.payload.length);
                this.payload.length = parseFloat(data.payload.length);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('PlaybackAdEvent', 'payload.type', data.payload.type);
        hasValue('PlaybackAdEvent', 'payload.type', 'video-ad', data.payload.type);
        this.payload.type = data.payload.type;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
