export function throttle(fn: any, wait = 400) {
    fn.waiting = false;
    return (...args) => {
        if (!fn.waiting) {
            fn(...args);
            fn.waiting = true;
            setTimeout(() => {
                fn.waiting = false;
            }, wait);
        }
    };
}

export function debounce(func, wait = 400) {
    let timeout: NodeJS.Timeout;
    return (...args) => {
        const later = () => {
            timeout = null;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
