/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { string, hasValue, object, oneOf, array, boolean, integer } from '../validators.js';

export class ErrorEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        try {
            if (data.schemaName !== undefined) {
                string('ErrorEvent', 'schemaName', data.schemaName);
                hasValue('ErrorEvent', 'schemaName', 'event-error', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-error';
            }
        } catch (error) {
        }
        object('ErrorEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('ErrorEvent', 'payload.type', data.payload.type);
        hasValue('ErrorEvent', 'payload.type', 'error', data.payload.type);
        this.payload.type = data.payload.type;
        string('ErrorEvent', 'payload.level', data.payload.level);
        oneOf('ErrorEvent', 'payload.level', ['fatal', 'error', 'warning', 'info', 'debug'], data.payload.level);
        this.payload.level = data.payload.level;
        string('ErrorEvent', 'payload.message', data.payload.message);
        this.payload.message = data.payload.message;
        try {
            if (data.payload.stacktrace !== undefined) {
                array('ErrorEvent', 'payload.stacktrace', data.payload.stacktrace);
                this.payload.stacktrace = data.payload.stacktrace.map((item) => {
                    const result = {};
                    try {
                        if (item !== undefined) {
                            object('ErrorEvent', 'item', item);
                            result.item = result.item || {};
                            try {
                                if (item.isConstructor !== undefined) {
                                    boolean('ErrorEvent', 'item.isConstructor', item.isConstructor);
                                    result.item.isConstructor = typeof item.isConstructor === 'string' ? item.isConstructor === 'true' : item.isConstructor;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.isEval !== undefined) {
                                    boolean('ErrorEvent', 'item.isEval', item.isEval);
                                    result.item.isEval = typeof item.isEval === 'string' ? item.isEval === 'true' : item.isEval;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.isNative !== undefined) {
                                    boolean('ErrorEvent', 'item.isNative', item.isNative);
                                    result.item.isNative = typeof item.isNative === 'string' ? item.isNative === 'true' : item.isNative;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.isToplevel !== undefined) {
                                    boolean('ErrorEvent', 'item.isToplevel', item.isToplevel);
                                    result.item.isToplevel = typeof item.isToplevel === 'string' ? item.isToplevel === 'true' : item.isToplevel;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.columnNumber !== undefined) {
                                    integer('ErrorEvent', 'item.columnNumber', item.columnNumber);
                                    result.item.columnNumber = parseInt(item.columnNumber, 10);
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.lineNumber !== undefined) {
                                    integer('ErrorEvent', 'item.lineNumber', item.lineNumber);
                                    result.item.lineNumber = parseInt(item.lineNumber, 10);
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.fileName !== undefined) {
                                    string('ErrorEvent', 'item.fileName', item.fileName);
                                    result.item.fileName = item.fileName;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.functionName !== undefined) {
                                    string('ErrorEvent', 'item.functionName', item.functionName);
                                    result.item.functionName = item.functionName;
                                }
                            } catch (error) {
                            }
                            try {
                                if (item.source !== undefined) {
                                    string('ErrorEvent', 'item.source', item.source);
                                    result.item.source = item.source;
                                }
                            } catch (error) {
                            }
                        }
                    } catch (error) {
                    }
                    return result.item;
                });
            }
        } catch (error) {
        }
        try {
            if (data.payload.source !== undefined) {
                string('ErrorEvent', 'payload.source', data.payload.source);
                this.payload.source = data.payload.source;
            }
        } catch (error) {
        }
    }
}
