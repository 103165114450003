function isPromise(value) {
  return typeof value === "object" && typeof value.then === "function";
}
export class FakePromise {
  state: string;
  value: any;
  then: (cb: any) => any;
  constructor(value?: any) {
    this.state = "fulfilled";
    this.value = value;
    this.then = function (cb) {
      try {
        const newValue = cb(this.value);
        if (isPromise(newValue)) {
          return newValue;
        }
        return new FakePromise(newValue);
      } catch (e) {
        return new FakeRejectPromise(e);
      }
    };
  }

  catch() {
    return this;
  }
}

export class FakeRejectPromise {
  state: string;
  reason: string;
  constructor(reason: string) {
    this.state = "rejected";
    this.reason = reason;
  }

  then(cb: (args: FakeRejectPromise) => void) {
    return cb(this);
  }

  catch(cb: (args: string) => void) {
    return cb(this.reason);
  }
}

FakePromise.prototype.then = function (cb) {
  try {
    const newValue = cb(this.value);
    if (isPromise(newValue)) {
      return newValue;
    }
    return new FakePromise(newValue);
  } catch (e) {
    return new FakeRejectPromise(e);
  }
};

//@ts-ignore
FakeRejectPromise.prototype.then = function (_, reject) {
  if (typeof reject === "function") {
    return new FakePromise(reject(this.reason));
  }
  return this;
};

FakeRejectPromise.prototype.catch = function (cb) {
  const newValue = cb(this.reason);
  return new FakePromise(newValue);
};
