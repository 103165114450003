/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, integer } from '../validators.js';

export class InscreenEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('InscreenEvent', 'schemaName', data.schemaName);
                hasValue('InscreenEvent', 'schemaName', 'event-inscreen', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-inscreen';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('InscreenEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('InscreenEvent', 'payload.type', data.payload.type);
        hasValue('InscreenEvent', 'payload.type', 'inscreen', data.payload.type);
        this.payload.type = data.payload.type;
        integer('InscreenEvent', 'payload.duration', data.payload.duration);
        this.payload.duration = parseInt(data.payload.duration, 10);
        try {
            if (data.payload.area !== undefined) {
                integer('InscreenEvent', 'payload.area', data.payload.area);
                this.payload.area = parseInt(data.payload.area, 10);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        integer('InscreenEvent', 'payload.deltaTimestamp', data.payload.deltaTimestamp);
        this.payload.deltaTimestamp = parseInt(data.payload.deltaTimestamp, 10);
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
