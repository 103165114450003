import {
  ADPUnionMemberEvent,
  ADPUnionMemberMeta,
  ADPUnionMemberPayload,
  RequireADPType,
} from "../../../../declarations/logger";
import { Events } from "../../../../declarations/store/state";

export type PubSubEvents = {
  [index: string]: Array<any>;
};

export type PubSubSubscribeType = "event" | "meta" | "util";

export type EventType<T extends PubSubSubscribeType> = T extends "event"
  ? ADPUnionMemberEvent
  : T extends "meta"
  ? ADPUnionMemberMeta
  : T extends "utils"
  ? "metaNodeAdded" | "startedDomLogging"
  : never;

/**
 * Som doc for class
 * {@link PubSub}
 */
export class PubSub<SubscribeType extends PubSubSubscribeType> {
  events: PubSubEvents;
  constructor() {
    this.events = {};
  }
  /**
   * {@link PubSub.subscribe}
   */
  subscribe<T extends EventType<SubscribeType>>(
    event: T,
    clb: (data?: RequireADPType<"payload", T>) => void
  ) {
    if (!this.events.hasOwnProperty(event)) this.events[event] = [];
    this.events[event].push(clb);
    return {
      unsubscribe: () => {
        this.events[event] = [];
      },
    };
  }

  unsubscribe(event: Events) {
    this.events[event] = [];
  }

  publish(event: string, data = {}) {
    if (!this.events.hasOwnProperty(event)) return [];

    return this.events[event].map((clb) => clb({ event, data }));
  }
}
