/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, uri } from '../validators.js';

export class Teaser extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('Teaser', 'schemaName', data.schemaName);
                hasValue('Teaser', 'schemaName', 'meta-teaser', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-teaser';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('Teaser', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('Teaser', 'payload.type', data.payload.type);
                oneOf('Teaser', 'payload.type', ['Article', 'NewsArticle', 'AdvertiserContentArticle', 'OpinionNewsArticle', 'ReviewNewsArticle', 'VideoObject', 'ChallengeDeck'], data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'Article';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('Teaser', 'payload.adpType', data.payload.adpType);
                hasValue('Teaser', 'payload.adpType', 'teaser', data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            } else if (!isExtended) {
                this.payload.adpType = 'teaser';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.variant !== undefined) {
                string('Teaser', 'payload.variant', data.payload.variant);
                this.payload.variant = data.payload.variant;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.testId !== undefined) {
                string('Teaser', 'payload.testId', data.payload.testId);
                this.payload.testId = data.payload.testId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.contentId !== undefined) {
                string('Teaser', 'payload.contentId', data.payload.contentId);
                this.payload.contentId = data.payload.contentId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.title !== undefined) {
                string('Teaser', 'payload.title', data.payload.title);
                this.payload.title = data.payload.title;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('Teaser', 'payload.url', data.payload.url);
        uri('Teaser', 'payload.url', data.payload.url);
        this.payload.url = data.payload.url;
        try {
            if (data.payload.contentModel !== undefined) {
                string('Teaser', 'payload.contentModel', data.payload.contentModel);
                oneOf('Teaser', 'payload.contentModel', ['paywall', 'registration', 'metered', 'free'], data.payload.contentModel);
                this.payload.contentModel = data.payload.contentModel;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.productFeature !== undefined) {
                string('Teaser', 'payload.productFeature', data.payload.productFeature);
                this.payload.productFeature = data.payload.productFeature;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
