import {
    StringError,
    NumberError,
    IntegerError,
    BooleanError,
    ArrayError,
    ObjectError,
    MultiError,
    UriError,
    DateTimeError,
    TimeError,
    DateError,
    EmailError,
    AllowedValuesError,
    AllowedValueError,
} from './errors.js';

const typeTest = {
  string: (prop) => typeof prop === "string",
  object: (prop) => Object.prototype.toString.call(prop) === "[object Object]",
  number: (prop) => !isNaN(parseFloat(prop)),
  integer: (prop) => !isNaN(parseInt(prop, 10)) && Number(prop) % 1 === 0,
  boolean: (prop) =>
    typeof prop === "boolean" || prop === "true" || prop === "false",
  array: (prop) => Array.isArray(prop),
};

export function string(className, key, prop) {
    if (!typeTest.string(prop)) {
        throw new StringError(className, key, prop);
    }
}

export function object(className, key, prop) {
    if (!typeTest.object(prop)) {
        throw new ObjectError(className, key);
    }
}

export function number(className, key, prop) {
    if (!typeTest.number(prop)) {
        throw new NumberError(className, key);
    }
}

export function integer(className, key, prop) {
    if (!typeTest.integer(prop)) {
        throw new IntegerError(className, key, prop);
    }
}

export function boolean(className, key, prop) {
    if (!typeTest.boolean(prop)) {
        throw new BooleanError(className, key);
    }
}

export function array(className, key, prop) {
    if (!typeTest.array(prop)) {
        throw new ArrayError(className, key);
    }
}

// Not an exact uri format. Also accepts relative uri
const uriPattern = /^(?:(?:[a-z][a-z0-9+-.]*:)?\/?\/)?(?:[^\\\s#][^\s#]*)?(?:#[^\\\s]*)?$/i;
export function uri(className, key, prop) {
    if (!uriPattern.test(prop)) {
        throw new UriError(className, key);
    }
}

const dateTimePattern = /^\d\d\d\d-[0-1]\d-[0-3]\d[t\s](?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d(?::?\d\d)?)$/i;
export function dateTime(className, key, prop) {
    if (!dateTimePattern.test(prop)) {
        throw new DateTimeError(className, key);
    }
}

const timePattern = /^(?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d(?::?\d\d)?)?$/i;
export function time(className, key, prop) {
    if (!timePattern.test(prop)) {
        throw new TimeError(className, key);
    }
}

const datePattern = /^\d\d\d\d-[0-1]\d-[0-3]\d$/;
export function date(className, key, prop) {
    if (!datePattern.test(prop)) {
        throw new DateError(className, key);
    }
}

const emailPattern = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i;
export function email(className, key, prop) {
    if (!emailPattern.test(prop)) {
        throw new EmailError(className, key);
    }
}

export function multitype(className, key, types, prop) {
    if (!types.some(type => typeTest[type](prop))) {
        console.log(`-----> Types ${types} Key ${key} Prop ${prop}`)
        throw new MultiError(className, types, key, prop);
    }
}

export function oneOf(className, key, allowedValues, prop) {
    if (!allowedValues.includes(prop)) {
        throw new AllowedValuesError(className, key, allowedValues, prop);
    }
}

export function hasValue(className, key, allowedValue, prop) {
    if (allowedValue !== prop) {
        throw new AllowedValueError(className, key, allowedValue, prop);
    }
}
