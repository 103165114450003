/**
 * @module /lib/events/error/stack-parser
 * @typicalname stackParser
 */

import { ErrorData } from '../../../../declarations/errors';
import { StackFrame } from './stackframe';

const CHROME_IE_STACK_REGEXP = /^\s*at .*(\S+:\d+|\(native\))/m;
const SAFARI_NATIVE_CODE_REGEXP = /^(eval@)?(\[native code])?$/;

function extractLocation(urlLike: string) {
    // Fail-fast but return locations like "(native)"
    if (!urlLike.includes(':')) {
        return [urlLike];
    }

    const regExp = /(.+?)(?::(\d+))?(?::(\d+))?$/;
    const parts = regExp.exec(urlLike.replace(/[()]/g, ''));
    return [parts[1], parts[2] || undefined, parts[3] || undefined];
}

function parseV8OrIE(error: ErrorData): StackFrame[] {
    return error.stack
        .split('\n')
        .filter(line => !!line.match(CHROME_IE_STACK_REGEXP))
        .map((line) => {
            if (line.includes('(eval ')) {
                // Throw away eval information
                line = line.replace(/eval code/g, 'eval').replace(/(\(eval at [^()]*)|(\),.*$)/g, '');
            }
            let sanitizedLine = line.replace(/^\s+/, '').replace(/\(eval code/g, '(');

            // capture and preseve the parenthesized location "(/foo/my bar.js:12:87)" in
            // case it has spaces in it, as the string is split on \s+ later on
            const location = sanitizedLine.match(/ (\((.+):(\d+):(\d+)\)$)/);

            // remove the parenthesized location from the line, if it was matched
            sanitizedLine = location ? sanitizedLine.replace(location[0], '') : sanitizedLine;

            const tokens = sanitizedLine.split(/\s+/).slice(1);
            // if a location was matched, pass it to extractLocation() otherwise pop the last token
            const locationParts = extractLocation(location ? location[1] : tokens.pop());
            const functionName = tokens.join(' ') || undefined;
            const fileName = ['eval', '<anonymous>'].includes(locationParts[0]) ? undefined : locationParts[0];

            return new StackFrame({
                functionName,
                fileName,
                lineNumber: locationParts[1],
                columnNumber: locationParts[2],
                source: line
            });
        });
}

function parseFFOrSafari(error: ErrorData): StackFrame[] {
    return error.stack
        .split('\n')
        .filter(line => !line.match(SAFARI_NATIVE_CODE_REGEXP))
        .map((line) => {
            // Throw away eval information
            if (line.includes(' > eval')) {
                line = line.replace(/ line (\d+)(?: > eval line \d+)* > eval:\d+:\d+/g, ':$1');
            }

            if (!line.includes('@') && !line.includes(':')) {
                // Safari eval frames only have function names and nothing else
                return new StackFrame({
                    functionName: line
                });
            }
            const functionNameRegex = /((.*".+"[^@]*)?[^@]*)(?:@)/;
            const matches = line.match(functionNameRegex);
            const functionName = matches && matches[1] ? matches[1] : undefined;
            const locationParts = extractLocation(line.replace(functionNameRegex, ''));

            return new StackFrame({
                functionName,
                fileName: locationParts[0],
                lineNumber: locationParts[1],
                columnNumber: locationParts[2],
                source: line
            });
        });
}

/**
 * Parses an Error object to a normalized [Stackframe](https://github.com/stacktracejs/error-stack-parser/)
 * object
 * @param {Error} error
 * @returns { StackFrame }
 */
export function parseErrorStack(error: ErrorData): StackFrame[] {
    if (!error.stack) {
        return [];
    }
    if (error.stack.match(CHROME_IE_STACK_REGEXP)) {
        return parseV8OrIE(error);
    }
    return parseFFOrSafari(error);
}
