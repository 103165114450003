import { addEvent } from "./add-passive-event";
import { ready } from "./dom-complete";
import { throttle } from "./throttle";

export const INACTIVITY_TIME = 10000; // 10 seconds

let timer: NodeJS.Timeout;
const dispatchInActivityEvent = throttle(() => {
  if (timer) {
    clearTimeout(timer);
  }
  //   console.log("User INACTIVE");
  window.dispatchEvent(new CustomEvent("adplogger:user-inactive"));
});

function resetTimer() {
  clearTimeout(timer);
  timer = setTimeout(() => {
    dispatchInActivityEvent();
  }, INACTIVITY_TIME);
}

const dispatchActivityEvent = throttle(() => {
  resetTimer();
  //   console.log("user ACTIVE");
  window.dispatchEvent(new CustomEvent("adplogger:user-active"));
});

// Events considered to indicate that a user is engaged with browsing the webpage
export const initActivityEvents = () => {
  [
    "mousedown",
    "keydown",
    "mousemove",
    "focus",
    "scroll",
    "resize",
    "video-active",
    "touchmove",
    "deviceorientation",
  ].forEach((evt) => addEvent(window, evt, dispatchActivityEvent));
}
  // Events considered to indicate that a user is not engaged with browsing the webpage
export const initInactivityEvents = () => {
  ["blur"].forEach((evt) => addEvent(window, evt, dispatchInActivityEvent));
}

if (typeof window !== "undefined") {




/*   
  resetTimer(); */
}

ready(initActivityEvents)
ready(initInactivityEvents)