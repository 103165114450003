export function getSelector(element: Node & Element) {
    if (element === document.documentElement) {
        return 'html';
    }
    if (element.id) {
        return `#${element.id}`;
    }
    let selector = '';

    // If it doesnt have a parent Element and the element is not the document Element
    // Assume we have a fragment
    if (!element.parentElement) {
        return 'documentFragment';
    }

    if (element.parentElement !== document.documentElement) {
        selector = `${getSelector(element.parentNode as Node & Element)} >`;
    }

    const siblings = Array.from(element.parentElement.children);

    const siblingInfo: any = siblings
        .filter(sibling => sibling !== element)
        .reduce((pv: any, sibling: any) => ({ 
            tagNames: [...pv.tagNames, sibling.tagName],
            classNames: [...pv.classNames, ...sibling.classList],
        }), { tagNames: [], classNames: [] });
    
    const classNames = [...[].slice.call(element.classList)]
        .filter(className => siblingInfo.classNames.includes(className));
    
    if (classNames.length > 0) {
        return `${selector} .${classNames[0]}`; 
    }

    if (!siblingInfo.tagNames.includes(element.tagName)) {
        return `${selector} ${element.tagName.toLowerCase()}`; 
    }

    const index = siblings.indexOf(element);
   
    return `${selector} :nth-child(${index + 1})`;
}
