import { getStore as originalGetStore } from "./store/global";
import { v4 as uuidv4 } from "uuid";
import { getPageUrl } from "../../utils";
import { isLocalhost } from "../../../core/get-environment";
import { getCommon } from "./get-common-data";
import { log } from "./logger";
import { storePromise } from "../../state/store/promise";

let getStore = originalGetStore;
const isLocalHost = location.hostname.includes("localhost");

export function mock(what: { getStore?: any }) {
  getStore = what.getStore || originalGetStore;
  return {
    restore: () => {
      mock({});
    },
  };
}

const blacklistedHosts = [];

async function useUserModule() {
  const store = await storePromise();

  if (
    (typeof window !== "undefined" &&
      blacklistedHosts.indexOf(window.location.host) > -1) ||
    //@ts-ignore
    store.state.initOptions?.exclude?.modules?.includes("user") ||
    isLocalHost
  ) {
    return Promise.resolve(false);
  }
  return Promise.resolve(true);
}

// Timeout after 1 ms, as amedia-user cannot be reached on localhost
// 0  ms is reserved for other uses in amedia-user.
export async function getUserKey() {
  const useUserModulePromise = await useUserModule();
  if (!useUserModulePromise) {
    return Promise.resolve(null);
  }
  const { UserDataRequest } = await import("@amedia/user");

  return new UserDataRequest()
    .withAttributes(["trackingKey"])
    .fetch({
      timeout: isLocalhost() ? 1 : 500,
    })
    .then(({ attributes, state }) => {
      if (state.isLoggedIn) {
        return attributes.trackingKey;
      }
      return "";
    })
    .catch(() => {
      return "";
    });
}

export async function logNonUserAccess() {
  const useUserModulePromise = await useUserModule();
  if (!useUserModulePromise) {
    return Promise.resolve(null);
  }
  const { UserDataRequest } = await import("@amedia/user");

  return new UserDataRequest()
    .withNonUserAccess()
    .fetch({
      timeout: isLocalhost() ? 1 : 500,
    })
    .then(({ nonUserAccess }) => {
      if (nonUserAccess && nonUserAccess.length > 0) {
        const { accessFeatures, customer } = nonUserAccess[0];
        const nonUserAccessObject = {
          accessFeatures: accessFeatures,
          customer: customer,
        };
        log<"CustomEvent">("CustomEvent", {
          type: "custom",
          name: "nonUserAccess",
          data: JSON.stringify(nonUserAccessObject),
        }).catch((e) => {
          console.error(
            `Adplogger: Error when creating nonUserAcces CustomEvent: ${e}`
          );
        });
      }
      return Promise.resolve(null);
    })
    .catch((e) => {
      return Promise.resolve(null);
    });
}

let pageViewId: string;
let previousUrl: string;
export function getPageViewId() {
  let url = getPageUrl();
  if (!pageViewId || previousUrl !== url) {
    pageViewId = uuidv4();
    previousUrl = url;
  }
  return Promise.resolve(pageViewId);
}

export function refreshPageViewId() {
  return getPageViewId();
}

self.addEventListener("adp:url-change", () => refreshPageViewId());

// Get and refresh lastVisitDates

const refreshLastVisitDates = (dateArray, domainBrowserId) => {
  try {
    const lastVisitDates = dateArray.join("|");
    window.localStorage.setItem(
      "amedia:lastvisitdates",
      `${domainBrowserId}:${lastVisitDates}`
    );
  } catch (error) {
    /* log.info(`Exception raised when setting value in localStorage: ${error}`); */
  }
};

function validateLastVisitDates(dateString) {
  const today = new Date();
  const dateLastWeek = new Date(Date.now() - 8 * 1000 * 60 * 60 * 24);
  let dateArray = [today.toISOString().slice(0, 10)];
  if (dateString) {
    dateString.split("|").forEach((date) => {
      let ts = new Date(date);
      if (ts.getTime() > dateLastWeek.getTime() && ts < today) {
        dateArray.push(ts.toISOString().slice(0, 10));
      }
    });
  }
  return [...new Set(dateArray.sort().reverse())];
}

export async function getLastVisitDates() {
  // Returns an array with the latest dates the users has visited within the last week
  const { domainBrowserId } = await getCommon();
  let lastVisitDates =
    window.localStorage.getItem("amedia:lastvisitdates") || "";
  const [storedBrowserId, dates] = lastVisitDates.split(":");
  let dateArray = validateLastVisitDates(
    domainBrowserId === storedBrowserId ? dates : ""
  );
  refreshLastVisitDates(dateArray, domainBrowserId);
  return dateArray;
}