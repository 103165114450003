/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, dateTime, uri } from '../validators.js';

export class Article extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('Article', 'schemaName', data.schemaName);
                hasValue('Article', 'schemaName', 'meta-article', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-article';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('Article', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('Article', 'payload.type', data.payload.type);
                oneOf('Article', 'payload.type', ['Article', 'NewsArticle', 'AdvertiserContentArticle', 'OpinionNewsArticle', 'ReviewNewsArticle'], data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'Article';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('Article', 'payload.adpType', data.payload.adpType);
                oneOf('Article', 'payload.adpType', ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'], data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.contentId !== undefined) {
                string('Article', 'payload.contentId', data.payload.contentId);
                this.payload.contentId = data.payload.contentId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.datePublished !== undefined) {
                string('Article', 'payload.datePublished', data.payload.datePublished);
                dateTime('Article', 'payload.datePublished', data.payload.datePublished);
                this.payload.datePublished = data.payload.datePublished;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.dateModified !== undefined) {
                string('Article', 'payload.dateModified', data.payload.dateModified);
                dateTime('Article', 'payload.dateModified', data.payload.dateModified);
                this.payload.dateModified = data.payload.dateModified;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.title !== undefined) {
                string('Article', 'payload.title', data.payload.title);
                this.payload.title = data.payload.title;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.byline !== undefined) {
                string('Article', 'payload.byline', data.payload.byline);
                this.payload.byline = data.payload.byline;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.isPartOf !== undefined) {
                string('Article', 'payload.isPartOf', data.payload.isPartOf);
                uri('Article', 'payload.isPartOf', data.payload.isPartOf);
                this.payload.isPartOf = data.payload.isPartOf;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.pageStart !== undefined) {
                string('Article', 'payload.pageStart', data.payload.pageStart);
                this.payload.pageStart = data.payload.pageStart;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.pageEnd !== undefined) {
                string('Article', 'payload.pageEnd', data.payload.pageEnd);
                this.payload.pageEnd = data.payload.pageEnd;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.articleSection !== undefined) {
                string('Article', 'payload.articleSection', data.payload.articleSection);
                this.payload.articleSection = data.payload.articleSection;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.tags !== undefined) {
                string('Article', 'payload.tags', data.payload.tags);
                this.payload.tags = data.payload.tags;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.contentModel !== undefined) {
                string('Article', 'payload.contentModel', data.payload.contentModel);
                oneOf('Article', 'payload.contentModel', ['paywall', 'registration', 'metered', 'free'], data.payload.contentModel);
                this.payload.contentModel = data.payload.contentModel;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.presentationMode !== undefined) {
                string('Article', 'payload.presentationMode', data.payload.presentationMode);
                oneOf('Article', 'payload.presentationMode', ['full', 'excerpt'], data.payload.presentationMode);
                this.payload.presentationMode = data.payload.presentationMode;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
