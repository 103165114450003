export const conditionsForCustomMicrodataParsing = ["http://schema.org/keywords"]
const srcHasValue = ['audio', 'embed', 'iframe', 'img', 'source', 'track', 'video'];
const hrefHasValue = ['a', 'area', 'link'];
const valueHasValue = ['data', 'meter'];

const customMicrodataParsing = {
    [conditionsForCustomMicrodataParsing[0]]: {
        transform: (text: string) => {
            return text.replace(/(\n)/g, ',')
        }
    }
}
export function getMicrodataPropertyValue(element: Element, name: string) {
    if (element.hasAttribute('content')) {
        return element.getAttribute('content');
    }
    const tagName = element.tagName.toLowerCase();
    if (srcHasValue.includes(tagName)) {
        return element.getAttribute('src');
    }
    if (hrefHasValue.includes(tagName)) {
        return element.getAttribute('href');
    }
    if (tagName === 'object') {
        return element.getAttribute('data');
    }
    if (valueHasValue.includes(tagName)) {
        return Number(element.getAttribute('value'));
    }
    if (tagName === 'time') {
        return element.getAttribute('datetime');
    }
    return conditionsForCustomMicrodataParsing.includes(name) 
        ? customMicrodataParsing[name].transform(element.textContent.trim()) 
        : element.textContent.trim();
}