import { RPCserver } from "@amedia/frontend-rpc";
import { initialize } from "./core/adp-variant";
import { initiateDom } from "./core/dom-complete";
import { setBackendUrl } from "./core/handler";
import { initKilkaya } from "./core/kilkaya";
import { register } from "./core/meta-utils/collect-meta-elements";

import "./core/events/click";
import { logError } from "./core/events/error/error";
import "./core/events/inscreen";
import "./core/events/custom";
import "./core/events/web-vitals";
import "./core/events/performance";
import { registerVideo } from "./core/events/video";
import { getBackendUrl } from "./core/get-backend-url";
import { addMetaElement, log, resetMetaTree } from "./shared/logger";
import { InitOptions } from "logger/declarations/options";
import Store from "./shared/state/store/store";
import { storePromise } from "./shared/state/store/promise";
import { version } from "./version";
import { notifyUpdate } from "./shared/logger/lib/logger";

const isLocalHost = location.hostname.includes("localhost");
let initialized = false;
let store: Store;

// Guard to handle multiple instances of adplogger
let guard = true;
if (typeof globalThis.Adplogger2 === "undefined") {
  Object.defineProperty(globalThis, "Adplogger2", {
    value: {
      version,
      ready: false,
    },
    enumerable: false,
    configurable: true,
    writable: true,
  });
  guard = false;
}

const createSDKBridge = () => {
  window.addEventListener(
    "adplogger-sdk:meta-element-added",
    async (ev: CustomEvent) => {
      //@ts-ignore
      const node = await addMetaElement(...(ev.detail as Array<any>));
      window.dispatchEvent(
        new CustomEvent("adplogger:meta-elements-added", {
          detail: [node.element],
        })
      );
      window.dispatchEvent(
        new CustomEvent("adplogger-core:meta-element-added", {
          detail: node,
        })
      );
    }
  );

  window.addEventListener(
    "adplogger-sdk:meta-event-added",
    async ({ detail }: CustomEvent) => {
      const { type, payload, eventElement } = detail;
      try {
        await log<typeof type>(type, payload, eventElement);
        notifyUpdate(eventElement);
        window.dispatchEvent(
          new CustomEvent("adplogger-core:meta-event-added", {
            detail: { isLogged: true, data: { type, payload } },
          })
        );
      } catch (error) {
        window.dispatchEvent(
          new CustomEvent("adplogger-core:meta-event-added", {
            detail: { isLogged: false, data: error },
          })
        );
      }
    }
  );
  window.addEventListener(
    "adplogger-sdk:dry-run",
    async ({ detail }: CustomEvent) => {
      store.dispatch("setDryRun", detail);
    }
  );
};

const startDOMLogging = (log) => {
  resetMetaTree(log, log && initiateDom);
};

function startADPLogger(config) {
  if (guard) {
    console.warn(
      `Adplogger ${version}: Detected another running instance. Exiting.`
    );
    return;
  }
  if (!config.url) {
    throw new Error("You must set a endpoint for adplogger");
  }
  setBackendUrl(config.url, store);
  !isLocalHost && initKilkaya();

  startDOMLogging(config?.log ?? true);
  createSDKBridge();
  initialize();

  window.dispatchEvent(new CustomEvent("adplogger.live"));
  window.addEventListener("adplogger.ready", () =>
    window.dispatchEvent(new CustomEvent("adplogger.live"))
  );

  window.addEventListener(
    "adplogger.logger",
    /** @type {CustomEvent} */ (evt: CustomEvent) => {
      const { method, args, resolve, reject } = evt.detail;
      if (!methods[method]) {
        reject(new Error(`adplogger does not have the method ${method}`));
      }
      try {
        const result = methods[method](...args);
        if (result && result.constructor && result.constructor === Promise) {
          result.then(resolve).catch(reject);
        } else {
          resolve(result);
        }
      } catch (e) {
        reject(e);
      }
    }
  );
}

const getEmptyOptions = () => ({
  exclude: {
    modules: [],
  },
  config: {},
});

export const init = async (options?: InitOptions) => {
  if (initialized) return;
  if (!options) {
    options = getEmptyOptions();
  }
  initialized = true;
  store = await storePromise();
  store.dispatch("setInitOptions", options);
  if (!store.state.startedDomLogging) {
    if (typeof window !== "undefined") {
      startADPLogger({ url: getBackendUrl() });
      console.log("ADPLOGGER INITIALIZED");
    }
  }
};

// Autostart module if no one is starting it explicitly
setTimeout(init);

const methods = {
  init: startADPLogger,
  log,
  //logCustom,
  logError,
  registerVideo,
  register,
};

export const rpcServer = new RPCserver({
  component: "adplogger",
  version: "1.0",
});

Object.keys(methods).forEach((name) =>
  rpcServer.addListener(name, methods[name])
);

/* export * as sdk from '../../sdk'; */
export * as utils from "./shared/utils";
