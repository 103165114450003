import { NoIndexedDB, WrongModeIndexedDB } from '../errors.js';
import { ExternalStore } from '../../../../../declarations/store'

const DB_NAME = 'adplogger-store';

function methodFactory(transaction: { objectStore: (arg0: any) => any; }, storeName: any, method: string | number, ...args: any[]) {
    const store = transaction.objectStore(storeName);
    const req = store[method](...args);
    return new Promise((resolve, reject) => {
        req.onerror = () => reject(req.error);
        req.onsuccess = () => resolve(req.result);
    });
}

function putUnavailable() {
    return Promise.reject(new WrongModeIndexedDB('Mode must be "readwrite" to use put/delete'));
}

export function getStore(name: string, mode = 'readonly'): Promise<ExternalStore> {
    if (typeof self.indexedDB === 'undefined') {
        return Promise.reject(new NoIndexedDB('IndexedDB not available'));
    }
    return new Promise((resolve, reject) => {
        const openreq = self.indexedDB.open(DB_NAME, 1);
        openreq.onerror = () => reject(openreq.error);
        openreq.onsuccess = () => {
            const db = openreq.result;
            // @ts-ignore
            const tx = db.transaction(name, mode);
            const put = mode === 'readwrite' ?
                methodFactory.bind(null, tx, name, 'put') : putUnavailable;
            const del = mode === 'readwrite' ?
                methodFactory.bind(null, tx, name, 'delete') : putUnavailable;
            resolve({
                put,
                del,
                get: methodFactory.bind(null, tx, name, 'get'),
                add: methodFactory.bind(null, tx, name, 'add'),
                tx,
                close: () => Promise.resolve(db.close()),
            });
        };
    
        // First time setup: create an empty object store
        openreq.onupgradeneeded = () => {
            openreq.result.createObjectStore("events");
        };
    });
}

export async function flush(name = 'events') {
    const store = await getStore(name, 'readwrite');
    const { tx } = store;
    const objectStore = tx.objectStore(name);
    let resolve: { (args: any[]): void; (value: unknown): void; };
    let reject: { (args: DOMException): any; (reason?: any): void; };
    const data = [];
    const result = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    const req = objectStore.openCursor();
    tx.onerror = () => reject(tx.error);
    // Older Safari do not support keyCursor
    req.onsuccess = (evt) => {
        const cursor = (evt.target as any).result;
        if (!cursor) {
            objectStore.clear().onsuccess = store.close;
            resolve(data);
            return;
        }
        data.push(cursor.value);
        cursor.continue();
    };
    return await result;
}
