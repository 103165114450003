/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, array, multitype } from '../validators.js';

export class LegacyEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('LegacyEvent', 'schemaName', data.schemaName);
                hasValue('LegacyEvent', 'schemaName', 'event-legacy', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-legacy';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('LegacyEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('LegacyEvent', 'payload.type', data.payload.type);
        hasValue('LegacyEvent', 'payload.type', 'legacy', data.payload.type);
        this.payload.type = data.payload.type;
        array('LegacyEvent', 'payload.data', data.payload.data);
        this.payload.data = data.payload.data.map((item) => {
            const result = {};
            try {
                if (item !== undefined) {
                    object('LegacyEvent', 'item', item);
                    result.item = result.item || {};
                    string('LegacyEvent', 'item.name', item.name);
                    result.item.name = item.name;
                    multitype('LegacyEvent', 'item.value', ['string'], item.value);
                    result.item.value = item.value;
                }
            } catch (error) {
                optionalFieldsErrors.push(error);
            }
            return result.item;
        });
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
