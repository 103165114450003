/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { Common } from './common.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, object, integer } from '../validators.js';

export class MetaElement extends Common {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('MetaElement', 'schemaName', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-element';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('MetaElement', 'payload', data.payload);
        this.payload = this.payload || {};
        string('MetaElement', 'payload.id', data.payload.id);
        this.payload.id = data.payload.id;
        try {
            if (data.payload.position !== undefined) {
                integer('MetaElement', 'payload.position', data.payload.position);
                this.payload.position = parseInt(data.payload.position, 10);
            } else if (!isExtended) {
                this.payload.position = 0;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.type !== undefined) {
                string('MetaElement', 'payload.type', data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'WebPageElement';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('MetaElement', 'payload.adpType', data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.parent !== undefined) {
                string('MetaElement', 'payload.parent', data.payload.parent);
                this.payload.parent = data.payload.parent;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.productFeature !== undefined) {
                string('MetaElement', 'payload.productFeature', data.payload.productFeature);
                this.payload.productFeature = data.payload.productFeature;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.cssSelector !== undefined) {
                string('MetaElement', 'payload.cssSelector', data.payload.cssSelector);
                this.payload.cssSelector = data.payload.cssSelector;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
