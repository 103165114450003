/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf } from '../validators.js';

export class CallToAction extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('CallToAction', 'schemaName', data.schemaName);
                hasValue('CallToAction', 'schemaName', 'meta-cta', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-cta';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('CallToAction', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('CallToAction', 'payload.type', data.payload.type);
                oneOf('CallToAction', 'payload.type', ['CallToAction'], data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'CallToAction';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('CallToAction', 'payload.contentId', data.payload.contentId);
        this.payload.contentId = data.payload.contentId;
        string('CallToAction', 'payload.adpType', data.payload.adpType);
        oneOf('CallToAction', 'payload.adpType', ['teaser', 'funnel', 'receipt'], data.payload.adpType);
        this.payload.adpType = data.payload.adpType;
        try {
            if (data.payload.teaserVariant !== undefined) {
                string('CallToAction', 'payload.teaserVariant', data.payload.teaserVariant);
                this.payload.teaserVariant = data.payload.teaserVariant;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.funnelStep !== undefined) {
                string('CallToAction', 'payload.funnelStep', data.payload.funnelStep);
                this.payload.funnelStep = data.payload.funnelStep;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.data !== undefined) {
                string('CallToAction', 'payload.data', data.payload.data);
                this.payload.data = data.payload.data;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
