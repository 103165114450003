/**
 * ## Console.log
 *
 * We add functionality to the native `console.log`
 * `console.log('adp', {CustomData});`
 * The first argument is the string 'adp' and the second argument is a
 * dictionary object or an array of objects
 * ```javascript
 * console.log('adp', [{
 *     name: 'MyDimention',
 *     value: true
 * }
 *     name: 'MyStuff',
 *     value: 20
 * }])
 * ```
 *
 *
 * ## Performance.Measure
 *
 * Every time you record a `performance.measure` we log the name of the
 * Measure and the duration as value
 * ```javascript
 * performace.mark('test');
 * setTimeout(() => performance.measure('MyName', 'test'), 100);
 * // Logs a custom dimention named 'MyName' with the value 100
 * ```
 * @module lib/events/custom
 * @typicalname custom
 */

import { ready } from "../dom-complete";

import { printDebugInfo } from "../meta-utils/print-debug-info";
import { getEnvironment } from "../get-environment";
import { getRoot, log, MetaNode } from "../../shared/logger";
/**
 * A dictionary for custom dimentions
 * @typedef {object} CustomData
 * @property {string} name - The identifier of the data
 * @property {string | number | boolean} value - The data value. Must be a primititve
 */

/**
 * Logs data  not defined as a type by adp. This should only be used for temporary logging
 * If you find that logging made through logCustom should be permanent, you should
 * create a type in ADP.
//  * @param {CustomData | CustomData[]} data
 * - An dictionary object or an array of dictionary objects
 * @param {HTMLElement | MetaNode} [element] - Element connected to the log event
 * @returns {Promise<void>}
 */

function logCustom(data: any, element: HTMLElement | MetaNode = getRoot(), name = ""): Promise<void> {
  data = JSON.stringify(data);
  return log<"CustomEvent">("CustomEvent", { type: "custom", data, name }, element);
}


let count = 1;
function customEventHandler(event: CustomEvent) {
  if (getEnvironment() === "localhost") {
    console.log("#", count, "ADPLogger2 recieved event: ", event);
    count += 1;
  }
  if(Array.isArray(event.detail)) {
    event.detail.forEach(detail => {
      const data = detail?.value || detail?.data || {}
      logCustom(data, detail?.element ?? getRoot(), detail?.name);
    })
  } else {
    const data = event.detail?.value || event.detail?.data || {}
    logCustom(data, event.detail?.element ?? getRoot(), event.detail?.name);
  }

  document.dispatchEvent(new Event("adplogger2:event-logged"));
}

function initCustomEvent() {
  document.addEventListener("adplogger2:custom-event", customEventHandler);
  document.addEventListener("adplogger2:ready", printDebugInfo);
  document.dispatchEvent(new Event("adplogger2:ready"));
}

ready(initCustomEvent)
