/**
 * All clicks in the DOM is captured by ADPlogger on the window object.
 * The Event is captured in the capture phase and an ADP Click Object
 * is created on the event . This object can be found on `event.adpClickEvent`.
 * We prevent the default navigation action on a-tags unless the target
 * attribute is set to `_blank`. We create a `event.preventAdpLogging`
 * method on the event. This means that if you would like to handle
 * click logging manually. You can attach a click listener to an element
 * in the bubbling phase and call preventAdpLogging.
 *
 * The preventDefault method is monkey patched So we know if the default
 * action is beeing prevented by another listener.
 *
 * In the bubbling phase of the event, we log the ADP Click Object unless
 * `preventAdpLogging` has been called. We then perform the page navigation.
 * @module lib/events/click
 * @typicalname click
 */

import { ADPClickEvent } from "../../../declarations/click";
import { setClickInSessionStorage } from "../adp-variant";
import { ready } from "../dom-complete";
import { getSelector } from "../../shared/utils";
import { getClosestMetaElement, log } from "../../shared/logger";

const adpDefaultPrevented = new Set();
let clickEvent: ADPClickEvent;

function wrapPreventDefault(e: Event) {
    const oldPreventDeafult = e.preventDefault.bind(e);
    if (e.defaultPrevented) {
        adpDefaultPrevented.add(e);
    }
    e.preventDefault = function () {
        adpDefaultPrevented.add(e);
        oldPreventDeafult();
    };
    oldPreventDeafult();
}

const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');

/**
 * @typedef {Object} AdpClickEvent
 * @mixes MouseEvent
 * @property {ClickEvent} adpClickEvent The Click Event data assosiated with the click
 * @property {function} preventAdpLogging Prevent the default logging of the click event
 */

/**
 * Set log data on the event object
 * @param {AdpClickEvent} e
 * @private
 */

// return the value of the attribute if it exists, otherwise return undefined
const getAttributeValue = (attributes: NamedNodeMap, attributeName: string) => {
    return attributes?.getNamedItem(attributeName)?.value || undefined;
}

function addClickData(e: ADPClickEvent) {
    // Adding composed path and using that to get the element for shadow root
    // Do not use e.path as it is not standard across the browsers
    const composedPath = e?.composedPath()
    // We need this because the target of shadow root is itself, and not the clicked element
    //@ts-ignore
    const target: (EventTarget & Element) = (e.target.shadowRoot && composedPath[0]) ? composedPath[0] : e.target
    
    const eventAttributes = target.attributes;
    let clickLabel = getAttributeValue(eventAttributes, "data-adp-clickLabel")
    let clickValue = getAttributeValue(eventAttributes, "data-adp-clickValue")

    if(!clickValue) {
        const clickValueElement = (composedPath?.find((element: Element) => element?.attributes?.getNamedItem('data-adp-clickValue')) as Element)?.attributes || undefined
        if(clickValueElement) clickValue = getAttributeValue(clickValueElement, "data-adp-clickValue")
    }
    if(!clickLabel) {
        const clickLabelElement = (composedPath?.find((element: Element) => element?.attributes?.getNamedItem('data-adp-clickLabel')) as Element)?.attributes || undefined
        if(clickLabelElement) clickLabel = getAttributeValue(clickLabelElement, "data-adp-clickLabel")
    }

    e.adpClickEvent = {
        cssSelector: getSelector(target),
        coordinateX: Math.round(e.pageX),
        coordinateY: Math.round(e.pageY),
        target: getClosestMetaElement(target),
        ... (clickLabel && { clickLabel: clickLabel }),
        ... (clickValue && { clickValue: clickValue }),
    };

    e.preventAdpLogging = () => {
        delete e.adpClickEvent;
    };

    const linkElement = e.composedPath()
        .filter(element => element instanceof Element)
        .find((element: Element) => element.tagName.toLowerCase() === 'a') as Element;

    if (linkElement) {
        const src = linkElement.getAttribute('href');
        if (src) {
            e.adpClickEvent.link = new URL(src, baseUrl).toString();
            // If the element is a normal link, we monkeypatch preventdefault
            // and set window.location.href instead of default action.
            // This give us the possibility of a async logging followed by a page navigation.
            if (
              linkElement.getAttribute("target") !== "_blank" &&
              !e.ctrlKey &&
              !e.metaKey
            ) {
              wrapPreventDefault(e);
            } else {
              e.newWindow = true;
            }
        }
    }
}

export function logClickEvent(e: ADPClickEvent) {
    // The click event is sometimes fired twice whereas the second event
    // is a timestamp (I think its a sideeffect from requestAnimationFrame).
    // Therefore, we only update the clickEvent if it contains a valid adpClickEvent.
    if (e.adpClickEvent) {
        clickEvent = e;
    }

    let timeStamp = Date.now();
    let alreadySent = false;

    function cleanUpAndRedirect(clickEvent: ADPClickEvent) {
        alreadySent = true;
        if (!clickEvent.adpClickEvent) {
            return;
        }
        const { link } = clickEvent.adpClickEvent;
        if (!link || adpDefaultPrevented.has(clickEvent)) {
            adpDefaultPrevented.delete(clickEvent);
            return;
        }
        if (!clickEvent.newWindow) {
            window.location.href = link;
        }
    }


    function checkForTimeOut() {
        // If the click event hangs (times out) for some reason, we redirect the user after x milliseconds
        // AnimationFrame is used to be able to check the timeSinceClick while the click is still being logged.
        // The check is done every ~16ms.
        if (alreadySent) {
            return
        }

        let timeRightNow = Date.now();
        let timeSinceClick = timeRightNow - timeStamp;
        if (timeSinceClick > 150) {
            cleanUpAndRedirect(clickEvent);
            return;
        }
        window.requestAnimationFrame(checkForTimeOut);
    }

    function sendClickData() {

        const { cssSelector, target, coordinateY, coordinateX, link, clickLabel, clickValue } = clickEvent.adpClickEvent;
        //TODO: Remove impl after AB testing is moved to backend
        setClickInSessionStorage(target.element)

        log<'ClickEvent'>('ClickEvent', {
            type: 'click',
            cssSelector,
            coordinateX,
            coordinateY,
            link,
            clickLabel,
            clickValue
        }, target)
            .catch(() => { }) // Always then
            .then(() => {
                if (alreadySent) {
                    return
                }
                cleanUpAndRedirect(clickEvent)
            });
    }

    try {
        checkForTimeOut()
        sendClickData();
    }

    // If something goes wrong, redirect the user.
    catch (e) {
        cleanUpAndRedirect(clickEvent);
    }

}



// Check if the disable click logging flag is set in the HTML root(document) tag.
function isClickLoggingDisabled() {
    const disabledLoggingTypes = self.document?.documentElement?.getAttribute(
        "data-disable-adplogger2-types"
    );
    return (disabledLoggingTypes?.includes("click"))
}

// add eventlisteners and enable click logging if not disabled
function activateClickLogging() {
    if (isClickLoggingDisabled()) {
        return;
    } else {
        self.addEventListener("click", addClickData, true);
        // Make sure the listener executes last to allow clients time to skip logging or change event data
        self.addEventListener(
            "click",
            (e: ADPClickEvent) => setTimeout(() => {
                //setClickInSessionStorage(e.target)
                logClickEvent(e)
            }, 0),
            false
        );
    }
}

ready(activateClickLogging)
