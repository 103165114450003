export function getPageUrl() {
  const { protocol, hostname, pathname, search } = self.location;
  return `${protocol}//${hostname}${pathname}${search}`;
}

export function removePiiFromUrl(url: string) {
  /* If str includes __pii__=true, remove everything after first question mark */
  if (url.toLowerCase().includes("__pii__=true")) {
    return url.split("?")[0];
  }
  return url;
}

function getURLWithoutHash() {
    return self.location.href.replace(self.location.hash, '');
}

function wrapHistoryMethods(methodName: string) {
    const historyMethod = history[methodName];
    history[methodName] = function (...args) {
        const oldUrl = getURLWithoutHash();
        historyMethod.apply(history, args);
        if (oldUrl !== getURLWithoutHash()) {
            self.dispatchEvent(new CustomEvent('adp:url-change'));
        }
    };
}
if (self.history && history.pushState) {
    wrapHistoryMethods('pushState');
    wrapHistoryMethods('replaceState');
}
