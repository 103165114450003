import { createNamespace } from '../../../utils';
import { FakePromise } from './fake-promise';

export function getStore(name: string) {
    const ns = createNamespace(`adpStore.${name}`);
    return new FakePromise({
        put: (value: any, key: string) => {
            ns[key] = value;
            return new FakePromise();
        },
        del: (key: string | number) => {
            delete ns[key];
            return new FakePromise();
        },
        get: (key: string | number) => new FakePromise(ns[key]),
        list: () => new FakePromise(ns.keys()),
        add: (value: any, key: string | number) => {
            ns[key] = value;
            return new FakePromise();
        },
        close: () => new FakePromise(),
    });
}

export function flush(name = 'events') {
    const ns = createNamespace(`adpStore.${name}`);
    const result = Object.keys(ns).map(key => ns[key]);
    //TODO: Remove any and give it a proper type
    /** @type Object */ (self as any).adpStore[name] = {};
    return new FakePromise(result);
}
