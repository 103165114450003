export function getEnvironment(): 'localhost' | 'snap' | 'prod' {
  const { hostname } = self.location;
  const localhostRegex = /localhost/g;
  const securehostRegex = /secure.amedia/g;
  const snapRegex = /no.snap[0-9]?[0-9].api.no/g;
  if (hostname.match(localhostRegex)) {
    return "localhost";
  }
  if (hostname.match(snapRegex) || hostname.match(securehostRegex)) {
    return "snap";
  } else {
    // We dont really know if it is prod, but we can assume it is.
    // Prod should be the safest mode to run in anyhow
    return "prod";
  }
}

export function isLocalhost() {
  const environment = getEnvironment();
  return environment.includes("localhost");
}
