import { flush } from "../shared/logger";
import Store from "../shared/state/store/store";
import { version } from "../version";
import { sendData } from "./send-data";
import { debounce } from "./throttle";

let send: (data: any) => Promise<any>;
let store: Store;

// Empty stores and send events
function flushEvents() {
  if (!send) {
    return;
  }
  flush("events")?.then(async (data: any) => {
    const dryRun = store?.state?.dryRun;

    if (dryRun) {
      if (data.length > 0) {
        console.groupCollapsed(`V:${version}. Dry run flush event`);
        data.forEach((d) => {
          const { payload } = d;
          delete d.payload;

          console.groupCollapsed(
            `V:${version}. Logging ${payload.metaType ?? payload.type} ${
              payload.type
            } event`
          );
          console.group("Event metadata");
          console.table(d);
          console.groupEnd();

          console.group("Event payload");
          console.table(payload);
          console.groupEnd();

          console.groupEnd();
        });
        console.groupEnd();
      }

      return;
    }
    send(data).catch((e) =>
      console.error(`V:${version}. Flush event error ${e}`)
    );
  });
}

self.addEventListener(
  "adplogger:store-update",
  debounce(() => flushEvents(), 100)
);
self.addEventListener("pagehide", flushEvents);
// self.addEventListener('blur', flushEvents);
self.addEventListener("adplogger:user-inactive", flushEvents);

export function setBackendUrl(url: string, initialStore: Store) {
  send = sendData.bind(null, url);
  store = initialStore;
  flushEvents();
}
