/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, uri } from '../validators.js';

export class MetaEmbed extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('MetaEmbed', 'schemaName', data.schemaName);
                hasValue('MetaEmbed', 'schemaName', 'meta-embed', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-embed';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('MetaEmbed', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('MetaEmbed', 'payload.type', data.payload.type);
                oneOf('MetaEmbed', 'payload.type', ['MetaEmbed'], data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'MetaEmbed';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('MetaEmbed', 'payload.adpType', data.payload.adpType);
                oneOf('MetaEmbed', 'payload.adpType', ['smartembed', 'component'], data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            } else if (!isExtended) {
                this.payload.adpType = 'smartembed';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.name !== undefined) {
                string('MetaEmbed', 'payload.name', data.payload.name);
                this.payload.name = data.payload.name;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('MetaEmbed', 'payload.url', data.payload.url);
        uri('MetaEmbed', 'payload.url', data.payload.url);
        this.payload.url = data.payload.url;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
