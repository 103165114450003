let responseEnd = Math.round(Date.now());

self.addEventListener('adp:url-change', () => {
    responseEnd = Date.now();
});

export function getEventData() {
    return Promise.resolve({
        // We cannot use perfomance.now since a single page app wont change time origin.
        deltaTimestamp: Date.now() - responseEnd,
    });
}
