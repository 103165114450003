import { NoIndexedDB, NoLocalStorage } from "../errors";
import { version } from "../../../../version";
import { v4 as uuidv4 } from "uuid";
import * as globalVar from "./global";
import * as webStorage from "./localstorage";
import * as idb from "./idb";
import { logError } from "../../../../core/events/error/error";

const libs = {
  globalVar,
  webStorage,
  idb,
};

let currentLib = "webStorage";

window.addEventListener("adplogger:store-init", () => {
  checkIfIDBisAvailable();
});

// Check if idb is available and functioning
async function checkIfIDBisAvailable() {
  if (typeof self.indexedDB === "undefined") {
    return Promise.reject(new NoIndexedDB("IndexedDB not available"));
  } else {
    idb.getStore("events", "readwrite").then((store: any) => {
      store
        .get("test")
        .then((value) => {
          if (value !== "idbtest") {
            store.add("idbtest", "test");
          }
        })
        .then(() => store.del("test"))
        .then(() => store.close())
        .then(() => upgradeToIDB())
        .catch((e) => {
          logError(
            {
              type: "error",
              message: `Browser cannot do necessary operations with IndexedDB. Continue using webstorage. ${e}`,
              level: "warning",
            },
            null
          );
        });
    });
  }
}

function upgradeToIDB() {
  currentLib = "idb";
  //TODO: This is casting an error. Figure out why
  //@ts-ignore
  Promise.all([webStorage.flush("events"), globalVar.flush("events")])
    //TODO: This is casting an error. Figure out why
    //@ts-ignore
    .then((d1, d2) => [
      {
        name: "events",
        value: { ...d1, ...d2 },
      },
    ])
    // Open the idb store and add all values to idb
    .then((dbs) => {
      Promise.all(
        dbs.map((db) =>
          idb
            .getStore(db.name, "readwrite")
            //TODO: Remove any and give it a proper type
            .then((store: any) =>
              Promise.all(
                Object.keys(db.value).map((key) =>
                  db.value[key].forEach((evt) => {
                    store.add(evt, uuidv4());
                  })
                )
              ).then(() => {
                store.close();
              })
            )
        )
      );
    })
    .catch((e) => {
      logError(
        {
          type: "error",
          message: `Error when transferring events from localstorage to IndexedDB. ${e}`,
          level: "warning",
        },
        null
      );
    });
}

function createMethod(methodName: string) {
  return function creator(...args: any[]) {
    const res = libs[currentLib][methodName](...args).catch((err) => {
      // downgrade on error
      if (err instanceof NoIndexedDB) {
        currentLib = "webStorage";
        return libs[currentLib][methodName](...args);
      }
      if (err instanceof NoLocalStorage) {
        currentLib = "globalVar";
        return libs[currentLib][methodName](...args);
      }
      throw err;
    });
    return res;
  };
}

export const getEvents = createMethod("getEvents");
export const getStore = createMethod("getStore");
export const flush = createMethod("flush");
