/**
 * This module registers HTML elements as ADP Meta Elements.
 * On load we select all elements that contain microdata, json-ld or matches
 * a registered css selector.
 * When nodes are inserted to the DOM these are also checked for microdata,
 * json-ld or a registered css selector.
 * Schema.org vocabularies are then mapped against our ADP types.
 * Meta Elements are not logged until an event that conencts to the element are logged
 *
 * @module meta-utils/collect-meta-elements
 * @typicalname metaElement
 */

import { v4 as uuidv4 } from "uuid";
import { addMetaElement, resetMetaTree } from "../../shared/logger";
import { ready } from "../dom-complete";
import { logError } from "../events/error/error";
import { getMicrodataPropertyValue } from "./collect-micodata-property";
import { mapToAdp } from "./map-to-adp";
const isURL = /https?:\/\//;
const trailingSlash = /\/$/;
const startSlash = /^\//;
const ldPrefix = /^(\w+):/;
const vocabPattern = /(.*\/).*$/;

// If we dont remove itemprops that are children of unrelated itemscopes (ie. metaElements)
// they will overwrite the itemprops of our current metaElement. Articles will become teasers and so on.
function removeOutOfScopeItemprops(
  currentMetaElement: Element,
  nestedProps: Array<Element>
) {
  let filteredList = nestedProps.filter((itemprop) => {
    const closestMetaElement = itemprop.closest("[itemscope]");
    if (currentMetaElement.isSameNode(closestMetaElement)) {
      return itemprop;
    }
  });
  return filteredList;
}

const getPropertyElements = (element: Element) => {
  const nestedElements: Array<Element> = [
    ...[].slice.call(element.querySelectorAll("[itemprop]")),
  ];
  const hasNestedItemScopes: Array<Element> = [
    ...[].slice.call(element.querySelectorAll("[itemscope]")),
  ];
  return { nestedElements, hasNestedItemScopes };
};

function getMicroData(element: Element): any {
  const type = element.getAttribute("itemtype");
  const id = element.getAttribute("itemid");
  if (!isURL.test(type)) {
    logError(
      {
        type: "error",
        message: `Itemtype (${type}) is not an URL. Page: ${location.href}`,
        level: "warning",
      },
      null
    );
    return {};
  }
  const vocab = type.match(vocabPattern)[1];

  let { nestedElements, hasNestedItemScopes } = getPropertyElements(element);

  if (hasNestedItemScopes.length > 0) {
    nestedElements = removeOutOfScopeItemprops(element, nestedElements);
  }
  return nestedElements.reduce(
    (pv, cv) => {
      let name = cv.getAttribute("itemprop");
      name = isURL.test(name) ? name : `${vocab}${name}`;
      if (cv.getAttribute("itemscope")) {
        pv[name] = getMicroData(cv);
      } else {
        pv[name] = getMicrodataPropertyValue(cv, name);
      }
      return pv;
    },
    { type, id }
  );
}

function getMicroDataExplicit(element: Element): any {
  const type = element.getAttribute("itemtype");
  if (!isURL.test(type)) {
    logError(
      {
        type: "error",
        message: `Itemtype (${type}) is not an URL. Page: ${location.href}`,
        level: "warning",
      },
      null
    );
    return {};
  }
  let { nestedElements, hasNestedItemScopes } = getPropertyElements(element);
  if (hasNestedItemScopes.length > 0) {
    nestedElements = removeOutOfScopeItemprops(element, nestedElements);
  }
  return nestedElements.reduce((pv, cv) => {
    let name = cv.getAttribute("itemprop");
    if (cv.getAttribute("itemscope")) {
      pv[name] = getMicroData(cv);
    } else {
      pv[name] = getMicrodataPropertyValue(cv, name);
    }
    return pv;
  }, {});
}
export function createClassname(str) {
  return str.replace(/\s([a-zA-Z])/g, (char) => char[1].toUpperCase());
}

function logElementsWithMicroData(elementSet: Set<Element>) {
  elementSet.forEach(async (element) => {
    const itemType = element.getAttribute("itemtype");
    if (itemType.startsWith("https://www.adplogger.no/json-schema/meta")) {
      const schemaName = itemType.split("/").pop();
      try {
        const microdata = getMicroDataExplicit(element);
        microdata.id = uuidv4();
        const { default: data } = await import(`./schemas/${schemaName}.js`);
        const payloadProperties = data.properties.payload.properties;
        Object.keys(payloadProperties).forEach((prop) => {
          const { default: def } = payloadProperties[prop];
          if (def && !microdata[prop]) {
            microdata[prop] = def;
          }
        });
        const { type } = microdata;
        addMetaElement(
          element,
          type,
          microdata,
          false,
          createClassname(data.title)
        );
      } catch (error) {
        console.log({ error });
      }
    } else {
      const microdata = getMicroData(element);
      const { data, type } = mapToAdp(microdata) as any;
      addMetaElement(element, type, data);
    }
  });
}

const querySelectors = {};
function logElementsWithCssSelector(elementMap: Map<string, Element[]>) {
  elementMap.forEach((elements, selector) => {
    elements.forEach((element) => {
      const { data, type } = JSON.parse(
        JSON.stringify(querySelectors[selector])
      );
      addMetaElement(element, type, data);
      querySelectors[selector].data.position += 1;
    });
  });
}
1;

function logElements(parent = document.body) {
  // If parent has shadow dom, we take shadow dom as parent
  if (parent.shadowRoot) {
    parent = parent.shadowRoot as unknown as HTMLElement;
  }
  // Should not log if data-adp-nolog is true
  if (typeof window !== "undefined") {
    const noLog = document.querySelector("html").getAttribute("data-adp-nolog");
    if (noLog === "true") {
      console.warn(
        "Adplogger2 is disabled because of data-adp-nolog property in html tag"
      );
      return;
    }
  }

  const elementsWithMicroData: Set<Element> = new Set([
    ...[].slice.call(parent.querySelectorAll("[itemscope]")),
  ]);
  const elementsWithABTest: Set<Element> = new Set([
    ...[].slice.call(parent.querySelectorAll("[data-adp-testid]")),
  ]);
  if (parent?.hasAttribute && parent?.hasAttribute("itemscope")) {
    elementsWithMicroData.add(parent);
  }
  const elementsFromSelector = new Map();

  Object.keys(querySelectors).forEach((selector) => {
    const matchedElements = [];
    if (parent.matches(selector)) {
      matchedElements.push(parent);
    }

    matchedElements.push(...(parent.querySelectorAll(selector) as any));
    if (matchedElements.length === 0) {
      return;
    }
    elementsFromSelector.set(
      selector,
      matchedElements.filter((el) => !elementsWithMicroData.has(el))
    );
  });

  logElementsWithMicroData(elementsWithMicroData);
  logElementsWithCssSelector(elementsFromSelector);
  //logElementsWithABTesting(elementsWithABTest)

  if (parent === document.body) {
    elementsWithMicroData.add(document.documentElement);
  }

  self.dispatchEvent(
    new CustomEvent("adplogger:meta-elements-added", {
      detail: [...elementsWithMicroData],
    })
  );
}

const onUrlChange = () => {
  window.addEventListener("adp:url-change", () => {
    resetMetaTree(true, logElements);
  });
};
ready(logElements);
ready(onUrlChange);

/**
 * Define an element or an CSS selector as an ADP Meta Element type
 * @param {string} type The type of Meta Element. Described in {@link ../../../../schemas|Schemas}
 * @param {Object} adpMetaObject The meta element payload
 * @param {HTMLElement} [element] An Optional HTML Element.
 * If not present you must supply an cssSelector
 */
export function register(
  type: any,
  adpMetaObject: { cssSelector: any; position: number },
  element: HTMLElement = null
) {
  if (!element) {
    const selector = adpMetaObject.cssSelector;
    if (selector) {
      if (Number.isInteger(adpMetaObject.position)) {
        adpMetaObject.position += 1;
      } else {
        adpMetaObject.position = 0;
      }
      querySelectors[selector] = {
        type,
        data: adpMetaObject,
      };
      // create copy
      const data = JSON.parse(JSON.stringify(adpMetaObject));
      document.querySelectorAll(selector).forEach((el) => {
        addMetaElement(el, type, data);
        adpMetaObject.position += 1;
      });
    } else {
      throw new Error("Missing property cssSelector on the adp meta object");
    }
  } else if (window.HTMLElement && element instanceof HTMLElement) {
    addMetaElement(element, type, adpMetaObject);
  } else {
    throw new Error("Element must be an HTML Element");
  }
}

let elements = [];
const elementBlackList = ["style", "link"];
const flushElements = () => {
  elements
    .filter(
      (el) =>
        el.nodeType === 1 &&
        !elementBlackList.includes(el.nodeName.toLowerCase())
    )
    .forEach((el) => logElements(el));

  elements = [];
};
const observer = new MutationObserver((mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.type === "childList") {
      if (
        (mutation.target as HTMLElement)?.hasAttribute("data-adp-log") &&
        (mutation.target as HTMLElement)?.getAttribute("data-adp-log") ===
          "false"
      ) {
        return;
      }
      elements.push(...[...(mutation.addedNodes as any)]);
    } else if (mutation.type === "attributes") {
      // This is for logging elements asynchronously. When attribute `data-adp-log` is true,
      // we log it.
      if ((mutation.target as HTMLElement)?.hasAttribute("data-adp-log")) {
        if (
          (mutation.target as HTMLElement)?.getAttribute("data-adp-log") ===
          "false"
        )
          return;
        else if (
          (mutation.target as HTMLElement)?.getAttribute("data-adp-log") ===
          "true"
        ) {
          elements.push(...[mutation.target as any]);
        }
      }
      if (mutation.target["localName"] === "bazaar-ad") {
        elements.push(...[mutation.target as any]);
      }
    }
  }
  flushElements();
});
observer.observe(document.documentElement, {
  childList: true,
  subtree: true,
  attributes: true,
});
