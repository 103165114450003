/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, integer } from '../validators.js';

export class ScrollEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('ScrollEvent', 'schemaName', data.schemaName);
                hasValue('ScrollEvent', 'schemaName', 'event-scroll', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-scroll';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('ScrollEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        integer('ScrollEvent', 'payload.start', data.payload.start);
        this.payload.start = parseInt(data.payload.start, 10);
        integer('ScrollEvent', 'payload.stop', data.payload.stop);
        this.payload.stop = parseInt(data.payload.stop, 10);
        integer('ScrollEvent', 'payload.duration', data.payload.duration);
        this.payload.duration = parseInt(data.payload.duration, 10);
        integer('ScrollEvent', 'payload.max', data.payload.max);
        this.payload.max = parseInt(data.payload.max, 10);
        string('ScrollEvent', 'payload.type', data.payload.type);
        hasValue('ScrollEvent', 'payload.type', 'scroll', data.payload.type);
        this.payload.type = data.payload.type;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
