import { Action, State } from "../../../../declarations/store/state";

const mutations: Record<
  keyof typeof Action,
  (state: State, payload: any) => State
> = {
  [Action.setInitOptions]: (state: State, payload: any) => {
    state.initOptions = payload;
    return state;
  },
  [Action.setStartedDomLogging]: (state: State, payload: any) => {
    state.startedDomLogging = payload;
    return state;
  },
  [Action.setRoot]: (state: State, payload: any) => {
    state.root = payload;
    return state;
  },
  [Action.addPage]: (state: State, payload: any) => {
    state.Pages.push(payload);
    return state;
  },
  [Action.addErrorEvent]: (state: State, payload: any) => {
    state.ErrorEvents.push(payload);
    return state;
  },
  [Action.addClickEvent]: (state: State, payload: any) => {
    state.ClickEvents.push(payload);
    return state;
  },
  [Action.addCommentEvent]: (state: State, payload: any) => {
    state.CommentEvents.push(payload);
    return state;
  },
  [Action.addMetaEmbed]: (state: State, payload: any) => {
    state.MetaEmbeds.push(payload);
    return state;
  },
  [Action.addMetaElement]: (state: State, payload: any) => {
    state.MetaElements.push(payload);
    return state;
  },
  [Action.addCustomEvent]: (state: State, payload: any) => {
    state.CustomEvents.push(payload);
    return state;
  },
  [Action.addInscreenEvent]: (state: State, payload: any) => {
    state.InscreenEvents.push(payload);
    return state;
  },
  [Action.addLegacyEvent]: (state: State, payload: any) => {
    state.LegacyEvents.push(payload);
    return state;
  },
  [Action.addPrebidEvent]: (state: State, payload: any) => {
    state.PrebidEvents.push(payload);
    return state;
  },
  [Action.addScrollEvent]: (state: State, payload: any) => {
    state.ScrollEvents.push(payload);
    return state;
  },
  [Action.addPlaybackAdEvent]: (state: State, payload: any) => {
    state.PlaybackAdEvents.push(payload);
    return state;
  },
  [Action.addVideoEvent]: (state: State, payload: any) => {
    state.VideoEvents.push(payload);
    return state;
  },
  [Action.addPerformanceEvent]: (state: State, payload: any) => {
    state.PerformanceEvents.push(payload);
    return state;
  },
  [Action.addWPAdBlock]: (state: State, payload: any) => {
    state.WPAdBlocks.push(payload);
    return state;
  },
  [Action.addArticle]: (state: State, payload: any) => {
    state.Articles.push(payload);
    return state;
  },
  [Action.addCollectionPage]: (state: State, payload: any) => {
    state.CollectionPages.push(payload);
    return state;
  },
  [Action.addCustomElement]: (state: State, payload: any) => {
    state.CustomElements.push(payload);
    return state;
  },
  [Action.addResource]: (state: State, payload: any) => {
    state.Resources.push(payload);
    return state;
  },
  [Action.addTeaser]: (state: State, payload: any) => {
    state.Teasers.push(payload);
    return state;
  },
  [Action.addVideo]: (state: State, payload: any) => {
    state.Videos.push(payload);
    return state;
  },
  [Action.addWebVitalsEvent]: (state: State, payload: any) => {
    state.WebVitalsEvents.push(payload);
    return state;
  },
  [Action.addAdslotEvent]: (state: State, payload: any) => {
    state.AdslotEvents.push(payload);
    return state;
  },
  [Action.addCallToAction]: (state: State, payload: any) => {
    state.CallToActions.push(payload);
    return state;
  },
  [Action.setDryRun]: (state: State, payload: any) => {
    state.dryRun = payload;
    return state;
  },
};

const actions: Record<
  keyof typeof Action,
  (context: any, payload: any) => void
> = {
  [Action.setInitOptions]: (context: any, payload: any) => {
    context.commit(Action.setInitOptions, payload);
  },
  [Action.setStartedDomLogging]: (context: any, payload: any) => {
    context.commit(Action.setStartedDomLogging, payload);
  },
  [Action.setRoot]: (context: any, payload: any) => {
    context.commit(Action.setRoot, payload);
  },
  [Action.addPage]: (context: any, payload: any) => {
    context.commit(Action.addPage, payload);
  },
  [Action.addErrorEvent]: (context: any, payload: any) => {
    context.commit(Action.addErrorEvent, payload);
  },
  [Action.addClickEvent]: (context: any, payload: any) => {
    context.commit(Action.addClickEvent, payload);
  },
  [Action.addCommentEvent]: (context: any, payload: any) => {
    context.commit(Action.addCommentEvent, payload);
  },
  [Action.addMetaEmbed]: (context: any, payload: any) => {
    context.commit(Action.addMetaEmbed, payload);
  },
  [Action.addMetaElement]: (context: any, payload: any) => {
    context.commit(Action.addMetaElement, payload);
  },
  [Action.addCustomEvent]: (context: any, payload: any) => {
    context.commit(Action.addCustomEvent, payload);
  },
  [Action.addInscreenEvent]: (context: any, payload: any) => {
    context.commit(Action.addInscreenEvent, payload);
  },
  [Action.addLegacyEvent]: (context: any, payload: any) => {
    context.commit(Action.addLegacyEvent, payload);
  },
  [Action.addPrebidEvent]: (context: any, payload: any) => {
    context.commit(Action.addPrebidEvent, payload);
  },
  [Action.addScrollEvent]: (context: any, payload: any) => {
    context.commit(Action.addScrollEvent, payload);
  },
  [Action.addPlaybackAdEvent]: (context: any, payload: any) => {
    context.commit(Action.addPlaybackAdEvent, payload);
  },
  [Action.addVideoEvent]: (context: any, payload: any) => {
    context.commit(Action.addVideoEvent, payload);
  },
  [Action.addPerformanceEvent]: (context: any, payload: any) => {
    context.commit(Action.addPerformanceEvent, payload);
  },
  [Action.addWPAdBlock]: (context: any, payload: any) => {
    context.commit(Action.addWPAdBlock, payload);
  },
  [Action.addArticle]: (context: any, payload: any) => {
    context.commit(Action.addArticle, payload);
  },
  [Action.addCollectionPage]: (context: any, payload: any) => {
    context.commit(Action.addCollectionPage, payload);
  },
  [Action.addCustomElement]: (context: any, payload: any) => {
    context.commit(Action.addCustomElement, payload);
  },
  [Action.addResource]: (context: any, payload: any) => {
    context.commit(Action.addResource, payload);
  },
  [Action.addTeaser]: (context: any, payload: any) => {
    context.commit(Action.addTeaser, payload);
  },
  [Action.addVideo]: (context: any, payload: any) => {
    context.commit(Action.addVideo, payload);
  },
  [Action.addWebVitalsEvent]: (context: any, payload: any) => {
    context.commit(Action.addWebVitalsEvent, payload);
  },
  [Action.addAdslotEvent]: (context: any, payload: any) => {
    context.commit(Action.addAdslotEvent, payload);
  },
  [Action.addCallToAction]: (context: any, payload: any) => {
    context.commit(Action.addCallToAction, payload);
  },
  [Action.setDryRun]: (context: any, payload: any) => {
    context.commit(Action.setDryRun, payload);
  },
};

export { actions, mutations };
