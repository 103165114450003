
import { ExtractADPProp } from "../../../declarations/logger";
import { log } from "../../shared/logger";

export class Logger {
  lastEventTime: number;
  sendCount: number;
  duration: number;
  element: any;
  
  constructor(element) {
    this.element = element;
    this.duration = 0;
    this.lastEventTime = undefined;
    this.sendCount = 0; // Used for selecting sending interval
  }
  send(onError?: (args: any) => void) {
    if (this.duration > 250) {
      const adpType: ExtractADPProp<'type', 'InscreenEvent'> = "InscreenEvent";
      const data = { type: "inscreen", duration: this.duration };
      const element = this.element;

      log<"InscreenEvent">(adpType, data, element).catch((error) => {
        console.error(`V. Could not create InscreenEvent Log. Error: ${error}`);
        const logEvent = { adpType, data, element, attempt: 1 };
        onError(logEvent);
      });
    }
  }
}
