import { getRoot, log } from "../../shared/logger";

// Expose internal variables for testings
let trackingCount = 0;
let timer;
const abTestData = []

 export function saveToSessionStorage(key, value) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        /* log.info(`Exception raised when setting value in localStorage: ${error}`); */
    }
}

 export function getFromSessionStorage(key) {
    let value;
    try {
        value = window.localStorage
            .getItem(key);
    } catch (error) {
        /* log.info(`Exception raised when retrieving key from localStorage: ${error}`); */
    }

    return value;
}

 function deleteFromSessionStorage(key) {
    try {
        window.localStorage
            .removeItem(key);
    } catch (error) {
        /* log.info(`Exception raised when removing key from localStorage: ${error}`); */
    }
}

export const initialize = (duration = 5000) => {

    //Check if page is frontepage
    const isFrontPage =
      document
        ?.querySelector("[data-isfrontpage]")
        ?.getAttribute("data-isfrontpage") == "true";
    if(isFrontPage) {
        deleteFromSessionStorage('adp_variant_click')
        return
    }

    const dataString = getFromSessionStorage('adp_variant_click');
    if (!dataString) {
        // Nothing to track
        return;
    }

    const hasAccess = Boolean(document?.querySelector('[data-access]')?.getAttribute('data-access'))

    if (!hasAccess) {
        return;
    }

    const data = JSON.parse(dataString);

    deleteFromSessionStorage('adp_variant_click');
    timer = setInterval(() => {
        if (trackingCount === 4) {
            clearInterval(timer);
            return;
        }
        
        if (data === null) {
            return;
        }
        
        const stringifiedData = JSON.stringify({
            type: 'adp_variant_read',
            data: data,
        })
        log<"CustomEvent">("CustomEvent", { type: "custom", name: 'adp_variant_read', data: stringifiedData }, getRoot())
        trackingCount += 1;
    }, duration);
}

export const getAbVariantForTeaser = (node) => {
    if(node) {
        if (node?.hasAttribute('data-adp-testid') &&
            node?.getAttribute('data-adp-testid') !== '') {
            return getABTestData(node);
        }
    }

    return undefined;
}

export const setClickInSessionStorage = (node) => {
    const abVariant = getAbVariantForTeaser(node);
    if (abVariant) {
        const stringifiedData = JSON.stringify({
            type: 'adp_variant_click',
            data:  abVariant,
        })

        log<"CustomEvent">("CustomEvent", { type: "custom", name: 'adp_variant_click', data: stringifiedData  }, node)
        saveToSessionStorage('adp_variant_click', abVariant)
    }
}

function getABTestData(node: any) {
    const siteid = node.getAttribute('data-adp-siteid');
    const teaserid = node.getAttribute('data-adp-teaserid');
    const variantid = node.getAttribute('data-adp-variantid');
    const testId = node?.getAttribute("data-adp-testid");
    const title = node?.querySelector("[itemprop=headline]")?.textContent;
    const imgurl = node?.querySelector("img")?.getAttribute("src");
    const position = 'fp'; // short for frontpage
    return {
      siteId: siteid,
      teaserId: teaserid,
      context: position,
      testId: testId,
      variantId: variantid,
      title: title,
      imgurl: imgurl,
    };
}


export const setABTestDataForTeaser = (element: Element) => {
    const data  = getAbVariantForTeaser(element)
    if(data) {
        const dataExists = abTestData.findIndex(ex => JSON.stringify(data) === JSON.stringify(ex) ) > -1
        if(!dataExists) {
            abTestData.push(data)
            const stringifiedData = JSON.stringify({
                type: 'adp_variant_click',
                data:  data,
            })
    
            log<"CustomEvent">("CustomEvent", { type: "custom", name: 'adp_variant_view', data: stringifiedData }, element)
        }
    } 
}