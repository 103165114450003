/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { Common } from './common.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, object, integer, oneOf } from '../validators.js';

export class AdpEvent extends Common {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('AdpEvent', 'schemaName', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('AdpEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        integer('AdpEvent', 'payload.deltaTimestamp', data.payload.deltaTimestamp);
        this.payload.deltaTimestamp = parseInt(data.payload.deltaTimestamp, 10);
        string('AdpEvent', 'payload.concernsMeta', data.payload.concernsMeta);
        this.payload.concernsMeta = data.payload.concernsMeta;
        try {
            if (data.payload.contentId !== undefined) {
                string('AdpEvent', 'payload.contentId', data.payload.contentId);
                this.payload.contentId = data.payload.contentId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.metaType !== undefined) {
                string('AdpEvent', 'payload.metaType', data.payload.metaType);
                oneOf('AdpEvent', 'payload.metaType', ['MetaElement', 'Page', 'WebPageElement', 'Article', 'Teaser', 'Video', 'navigation', 'resource', 'meta-comment', 'meta-custom', 'meta-error', 'meta-prebid', 'meta-legacy', 'NewsArticle', 'AdvertiserContentArticle', 'OpinionNewsArticle', 'ReviewNewsArticle', 'CollectionPage', 'ImageGallery', 'VideoGallery', 'CustomElement', 'WPAdBlock', 'MetaEmbed', 'CallToAction'], data.payload.metaType);
                this.payload.metaType = data.payload.metaType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('AdpEvent', 'payload.type', data.payload.type);
        oneOf('AdpEvent', 'payload.type', ['click', 'scroll', 'inscreen', 'purchase', 'registration', 'video', 'video-ad', 'comment', 'custom', 'error', 'prebid', 'legacy', 'performance', 'web-vitals', 'adslot'], data.payload.type);
        this.payload.type = data.payload.type;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
