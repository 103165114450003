function getAttribute(attribute: string) {
  return attributeExistsInElement(attribute) ? getAttributeFromElement(attribute) : null;
}

function attributeExistsInElement(attribute: string) {
  return self.document &&
    self.document.documentElement &&
    self.document.documentElement.getAttribute(attribute) ? true : false
}

function getAttributeFromElement(attribute: string) {
  return self.document.documentElement.getAttribute(attribute)
}

export { getAttribute, attributeExistsInElement };