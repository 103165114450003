/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */
const isURL = /^(https?:|)\/\//;
const protocolPattern = /https?:/;
const context = {
    WPAdBlock: {
        vocab: '//schema.org/',
        '//schema.org/WPAdBlock': 'WPAdBlock',
        '//www.adplogger.no/json-schema/meta-ad#unit': 'unit',
        '//www.adplogger.no/json-schema/meta-ad#lineItem': 'lineItem',
        '//www.adplogger.no/json-schema/meta-ad#advertiser': 'advertiser',
        '//www.adplogger.no/json-schema/meta-ad#creative': 'creative',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType',
        '//www.adplogger.no/json-schema/meta-ad#order': 'order'
    },
    Article: {
        vocab: '//schema.org/',
        '//schema.org/identifier': 'contentId',
        '//schema.org/headline': 'title',
        '//schema.org/author': 'byline',
        '//schema.org/keywords': 'tags',
        '//www.adplogger.no/json-schema/meta-article#contentModel': 'contentModel',
        '//www.adplogger.no/json-schema/meta-article#articleSection': 'articleSection',
        '//www.adplogger.no/json-schema/meta-article#presentationMode': 'presentationMode',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType',
        '//www.adplogger.no/json-schema/meta-element#productFeature': 'productFeature'
    },
    CollectionPage: {
        vocab: '//schema.org/',
        '//schema.org/identifier': 'contentId',
        '//schema.org/headline': 'title',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType'
    },
    CallToAction: {
        vocab: '//www.adplogger.no/json-schema/',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType'
    },
    CustomElement: {
        vocab: '//www.adplogger.no/json-schema/',
        '//www.adplogger.no/json-schema/custom-element#name': 'name',
        '//www.adplogger.no/json-schema/custom-element#data': 'data',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType'
    },
    MetaElement: {
        vocab: '//www.adplogger.no/json-schema/meta-element/#',
        '//www.adplogger.no/json-schema/meta-element#parent': 'parent',
        '//www.adplogger.no/json-schema/meta-element#productFeature': 'productFeature',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType'
    },
    MetaEmbed: {
        vocab: '//www.adplogger.no/json-schema/',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType'
    },
    Page: {
        vocab: '//www.adplogger.no/json-schema/meta-page/#',
        '//schema.org/Page': 'Page',
        '//schema.org/name': 'name',
        '//schema.org/url': 'url',
        '//www.adplogger.no/json-schema/meta-page#pageModel': 'pageModel',
        '//www.adplogger.no/json-schema/meta-page#isFrontPage': 'isFrontPage'
    },
    Resource: {
        vocab: '//www.adplogger.no/json-schema/meta-resource/#'
    },
    Teaser: {
        vocab: '//schema.org/',
        '//schema.org/headline': 'title',
        '//schema.org/identifier': 'contentId',
        '//www.adplogger.no/json-schema/meta-element#adpType': 'adpType',
        '//www.adplogger.no/json-schema/meta-teaser#testid': 'testId',
        '//www.adplogger.no/json-schema/meta-teaser#variant': 'variant',
        '//www.adplogger.no/json-schema/meta-element#productFeature': 'productFeature'
    },
    Video: {
        vocab: '//schema.org/',
        '//schema.org/VideoObject': 'Video',
        '//schema.org/url': 'src',
        '//schema.org/duration': 'duration',
        '//schema.org/startTime': 'startTime',
        '//schema.org/headline': 'title',
        '//schema.org/category': 'category',
        '//schema.org/contentId': 'contentId',
        '//schema.org/length': 'length',
        '//schema.org/playerId': 'playerId',
        '//schema.org/live': 'live'
    }
};

function getContext(type, adpType) {
    if (type === '//schema.org/WPAdBlock') {
        return 'WPAdBlock';
    }
    if (type === '//schema.org/Article' && ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'].includes(adpType)) {
        return 'Article';
    }
    if (type === '//schema.org/Article' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/NewsArticle' && ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'].includes(adpType)) {
        return 'Article';
    }
    if (type === '//schema.org/NewsArticle' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/AdvertiserContentArticle' && ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'].includes(adpType)) {
        return 'Article';
    }
    if (type === '//schema.org/AdvertiserContentArticle' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/OpinionNewsArticle' && ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'].includes(adpType)) {
        return 'Article';
    }
    if (type === '//schema.org/OpinionNewsArticle' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/ReviewNewsArticle' && ['story', 'opinion', 'gallery', 'video', 'embed', 'poll', 'review', 'ytring', 'section', 'feature', 'livecenter', 'commercial'].includes(adpType)) {
        return 'Article';
    }
    if (type === '//schema.org/ReviewNewsArticle' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/CollectionPage') {
        return 'CollectionPage';
    }
    if (type === '//schema.org/ImageGallery') {
        return 'CollectionPage';
    }
    if (type === '//schema.org/VideoGallery') {
        return 'CollectionPage';
    }
    if (type === '//www.adplogger.no/json-schema/CallToAction') {
        return 'CallToAction';
    }
    if (type === '//www.adplogger.no/json-schema/CustomElement') {
        return 'CustomElement';
    }
    if (type === '//www.adplogger.no/json-schema/meta-element/#WebPageElement') {
        return 'MetaElement';
    }
    if (type === '//www.adplogger.no/json-schema/MetaEmbed') {
        return 'MetaEmbed';
    }
    if (type === '//schema.org/Page') {
        return 'Page';
    }
    if (type === '//www.adplogger.no/json-schema/meta-resource/#navigation') {
        return 'Resource';
    }
    if (type === '//www.adplogger.no/json-schema/meta-resource/#resource') {
        return 'Resource';
    }
    if (type === '//schema.org/VideoObject' && ['teaser'].includes(adpType)) {
        return 'Teaser';
    }
    if (type === '//schema.org/VideoObject' && !adpType) {
        return 'Video';
    }
    if (type === '//schema.org/ChallengeDeck') {
        return 'Teaser';
    }
    return 'MetaElement';
}

export function mapToAdp(microData) {
    //Only needed for tags. TODO: Find a better way to handle
    if (microData['http://schema.org/keywords'] && microData['https://www.adplogger.no/json-schema/meta-article#tags']) {
        microData['http://schema.org/keywords'] = 
            [
                ...microData['http://schema.org/keywords'].split(','), 
                ...microData['https://www.adplogger.no/json-schema/meta-article#tags'].split(',')
            ]
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(',');
    }
    const adpType = microData['https://www.adplogger.no/json-schema/meta-element#adpType'] ||
        microData['http://www.adplogger.no/json-schema/meta-element#adpType'] ||
        Object.keys(microData).reduce((pv, key) => (key.includes('adpType') ? microData[key] : pv), undefined);

    const type = getContext(microData?.className?.replace(protocolPattern, '') || microData.type.replace(protocolPattern, ''), adpType);
    const ctx = context[type];

    const data = Object.keys(microData)
        .reduce((result, key) => {
            const value = microData[key];
            if (key === 'type') {
                result[key] = ctx[value.replace(protocolPattern, '')] ||  value.replace(ctx.vocab, '').replace(protocolPattern, '');
                return result;
            }
            if (!isURL.test(key)) {
                result[key] = value;
                return result;
            }
            const urlKey = key.replace(protocolPattern, '');
            if (ctx[urlKey]) {
                result[ctx[urlKey]] = value;
                return result;
            }
            result[urlKey.replace(ctx.vocab, '')] = value;
            return result;
        }, {});
    return {
        data,
        type,
    };
}
