import { ready } from "../dom-complete";
import { onCLS, onFCP, onLCP, onTTFB } from "web-vitals";
import { getRoot, log } from "../../shared/logger";
import { version } from "../meta-utils/version";


const TIMEOUT = 5000

const constructPayload = (name: string, value: number, func: void) => ({
  name,
  value,
  func,
});

const constructErrorPayload = (index: number) => {
  switch (index) {
    case 0:
      return constructPayload(
        "CLS",
        0,
        console.warn.bind(this, "Cannot get web vital metric CLS")
      );
    case 1:
      return constructPayload(
        "LCP",
        0,
        console.warn.bind(this, "Cannot get web vital metric LCP")
      );
    case 2:
      return constructPayload(
        "FCP",
        0,
        console.warn.bind(this, "Cannot get web vital metric FCP")
      );
    case 3:
      return constructPayload(
        "TTFB",
        0,
        console.warn.bind(this, "Cannot get web vital metric TTFB")
      );
  }
};

const getWebVitalsMetric = (
  funcs: Array<(onReport: (metric: any) => void, opts?: any) => void>
) => {
  return funcs.map(
    (func, index) =>
      new Promise((res, rej) => {
        func((metric) => res(metric), { reportAllChanges: true });
        setTimeout(() => {
          rej(constructErrorPayload(index));
        }, index * TIMEOUT);
      })
  );
};

const initWebVitalsEvent = async () => {
  try {
    const allWebVitals = (await Promise.allSettled(
      getWebVitalsMetric([onCLS, onLCP, onFCP, onTTFB])
    )) as Array<any>;

    allWebVitals.forEach(
      (result) => result.status === "rejected" && result.reason.func()
    );

    const normalizedWebVitals: { name: string; value: number } =
      allWebVitals.reduce((reducer: any, data) => {
        if (data.status === "rejected") {
          reducer = {
            ...reducer,
            [data?.reason?.name?.toLowerCase()]: data?.reason?.value,
          };
        } else {
          reducer = {
            ...reducer,
            [data?.value?.name?.toLowerCase()]: Number(
              data?.value?.value?.toFixed(2)
            ),
          };
        }
        return reducer;
      }, {}) as { name: string; value: number };

    try {
      await log<"WebVitalsEvent">(
        "WebVitalsEvent",
        {
          ...normalizedWebVitals,
          type: "web-vitals",
          metaType: "Page",
        },
        getRoot()
      );
    } catch (error) {
      console.warn(`V:${version}. ${error}`);
    }
  } catch (error) {
    console.warn(`V:${version}. ${error}`);
  }
};

ready(initWebVitalsEvent);
