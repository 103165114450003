import { version } from "../version";
const browserSupportsKeepalive =
  typeof Request !== "undefined" &&
  "keepalive" in new Request("https://www.google.com");
const err = "";


/**
 * Internal function using the Fetch API to send data to sero.
 *
 * @param  {string} url Endpoint for request
 * @param  {string} data Key-value pairs as query string
 * @param  {boolean} dropKeepAlive boolean indicating whether the fetch request should have the keepalive attribute
 * @return {Promise} Resolves the request with a message
 * @ignore
 */

function performRequestWithFetch(
  url: string,
  data: string,
  dropKeepAlive: any
) {
  const customHeaders = constructHeaders(data);
  return window.fetch(url, {
    method: "POST",
    body: data,
    keepalive: !dropKeepAlive,
    mode: "cors",
    credentials: "omit",
    headers: new Headers(customHeaders),
  });
}

function constructHeaders(data: string) {
  const payloadObject = JSON.parse(data)[0];
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-ADP-Site", payloadObject.siteKey);
  headers.append("X-ADP-Logger", payloadObject.logger);
  if ("useKey" in payloadObject) {
    headers.append("X-ADP-User", payloadObject.userKey);
  }
  return headers;
}

function byteCount(s: string) {
  return encodeURI(s).split(/%..|./).length - 1;
}

let queue = [];
// This is the max limit Sero has for each payload. If adplogger send a payload above this size, Sero will not accept it.
const MAX = 20000;
let sending = false;

export function sendData(
  url: string,
  data: any[],
  maxBytes = MAX,
  retries = 0,
  ctx = { performRequestWithFetch, browserSupportsKeepalive, err }
) {
  const respond = (queuestate) => {
    queue = queue.filter((i) => !queuestate.includes(i));
    if (queue.length > 0) {
      return new Promise((resolve, reject) => {
        sendData(url, [], maxBytes, 0, ctx)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
    return;
  };

  queue.push(...data);

  if (retries >= 5) {
    return Promise.reject(
      new Error(
        `V:${version}. Too many retries. Last error: ${ctx.err} on url: ${url}`
      )
    );
  }
  if (!sending) {
    let queuestate = [...queue]; // shallow copy
    let payload = JSON.stringify(queuestate);
    let b = byteCount(payload);
    while (b > maxBytes && queuestate.length > 1) {
      queuestate = queuestate.slice(0, Math.ceil(queuestate.length / 2));
      payload = JSON.stringify(queuestate);
      b = byteCount(payload);
    }
    if (b > 2) {
      sending = true;
      // we want to use keepalive when possible. if fetch is polyfilled we cannot
      const dropKeepAlive = !ctx.browserSupportsKeepalive;
      return (
        ctx
          .performRequestWithFetch(url, payload, dropKeepAlive)
          //TODO: Remove any and give it a proper type
          .then((response: Response) => {
            sending = false;
            if (!response.ok) {
              console.error(
                `V:${version}. Response failed: ${response.status} - ${response.statusText}`
              );
              throw Error(
                `Response failed: ${response.status} - ${response.statusText}`
              );
            }
            respond(queuestate);
            return response;
          })
          .catch((err) => {
            sending = false;
            if (
              err.name === "TypeError" &&
              (err.message === "Failed to fetch" ||
                err.message === "Type error")
            ) {
              respond(queuestate);
              return Promise.resolve("sending assumed ok");
            }
            console.error(
              `V:${version}. Send error, bytes=${b}, keepalive=${!dropKeepAlive} error: ${err}`
            );
            // queue = queue.filter((i) => !queuestate.includes(i)); // TODO: Decide - Keep or remove
            ctx.err = err;
            // turn off keepalive in retries - in case this is a fetch polyfill which falsely reports keepalive capabilities
            ctx.browserSupportsKeepalive = false;
            return new Promise((resolve, reject) =>
              setTimeout(() => {
                sendData(url, [], maxBytes, retries + 1, ctx)
                  .then((r) => resolve(r))
                  .catch((e) => {
                    console.error(`V:${version}. Error while sending: ${e}`);
                    reject(e);
                  });
              }, 250)
            );
          })
      );
    }
  }
  return Promise.resolve("sending queued");
}
