// @ts-nocheck
import { ready } from "../dom-complete";
import { getRoot, log } from "../../shared/logger";
import { version } from "../meta-utils/version";

const paintMapping = {
  "first-contentful-paint": "fcp",
  "first-paint": "fp",
};
const initPerformanceEvent = async () => {
  const paintPerformance = performance.getEntriesByType("paint");
  const timingPerformance = performance.getEntriesByType("navigation");

  const payload = Object.keys(paintMapping).reduce((acc, key) => {
    acc = {
      ...acc,
      [paintMapping[key]]: Math.round(
        paintPerformance?.find((p) => p.name === key)?.startTime ?? 0
      ),
    };
    return acc;
  }, {});

  payload.domComplete = Math.round(timingPerformance[0]?.domComplete) ?? 0;
  payload.domInteractive =
    Math.round(timingPerformance[0]?.domInteractive) ?? 0;
  payload.domContentLoaded =
    Math.round(timingPerformance[0]?.domContentLoadedEventEnd) ?? 0;

  try {
    await log<"PerformanceEvent">(
      "PerformanceEvent",
      {
        ...payload,
        type: "performance",
        metaType: "Page",
      },
      getRoot()
    );
  } catch (error) {
    console.error(
      `V:${version}. ${error}`
    );
  }
};

ready(initPerformanceEvent);
