/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object } from '../validators.js';

export class CustomEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('CustomEvent', 'schemaName', data.schemaName);
                hasValue('CustomEvent', 'schemaName', 'event-custom', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-custom';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('CustomEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('CustomEvent', 'payload.type', data.payload.type);
        hasValue('CustomEvent', 'payload.type', 'custom', data.payload.type);
        this.payload.type = data.payload.type;
        try {
            if (data.payload.data !== undefined) {
                string('CustomEvent', 'payload.data', data.payload.data);
                this.payload.data = data.payload.data;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.name !== undefined) {
                string('CustomEvent', 'payload.name', data.payload.name);
                this.payload.name = data.payload.name;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
