
import { v4 as uuidv4 } from "uuid";
import { getSelector } from "../../utils";
import { MetaNode } from "./meta-tree";


const idMap = new WeakMap();

export function setCommonMeta(metaTreeElement: MetaNode) {
  if (!metaTreeElement) {
    throw new Error("setMetaIdAndParent is called without an element");
  }
  (function recurse(node) {
    const { parent } = node;
    if (parent) {
      if (!parent.data.id) {
        recurse(parent);
      }
      node.data.parent = parent.data.id;
    }
    if (!node.data.cssSelector) {
      node.data.cssSelector = getSelector(node.element);
    }

    if (!node.data.id) {
      let uuid = uuidv4();
      const uuidImmutable = uuid.slice(); // Deep copy to ensure uuid never changes. Not sure if necessary
      node.data.id = uuidImmutable;
      Object.defineProperty(node.data, "id", {
        get: () => {
          return uuidImmutable;
        },
        set: (id) => {
          // Still allow the id to be overwritten
          idMap.set(node.data, id);
        },
        enumerable: true,
      });
    }

    return node.data.id;
  })(metaTreeElement);
}
