/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf } from '../validators.js';

export class CollectionPage extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('CollectionPage', 'schemaName', data.schemaName);
                hasValue('CollectionPage', 'schemaName', 'meta-collection-page', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-collection-page';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('CollectionPage', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('CollectionPage', 'payload.type', data.payload.type);
                oneOf('CollectionPage', 'payload.type', ['CollectionPage', 'ImageGallery', 'VideoGallery'], data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'CollectionPage';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('CollectionPage', 'payload.adpType', data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.contentId !== undefined) {
                string('CollectionPage', 'payload.contentId', data.payload.contentId);
                this.payload.contentId = data.payload.contentId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('CollectionPage', 'payload.title', data.payload.title);
        this.payload.title = data.payload.title;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
