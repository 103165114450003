/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, number, boolean, integer } from '../validators.js';

export class PrebidEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('PrebidEvent', 'schemaName', data.schemaName);
                hasValue('PrebidEvent', 'schemaName', 'event-prebid', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-prebid';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('PrebidEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('PrebidEvent', 'payload.bidder', data.payload.bidder);
        this.payload.bidder = data.payload.bidder;
        string('PrebidEvent', 'payload.adUnitCode', data.payload.adUnitCode);
        this.payload.adUnitCode = data.payload.adUnitCode;
        try {
            if (data.payload.adType !== undefined) {
                string('PrebidEvent', 'payload.adType', data.payload.adType);
                oneOf('PrebidEvent', 'payload.adType', ['banner', 'native'], data.payload.adType);
                this.payload.adType = data.payload.adType;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        number('PrebidEvent', 'payload.cpm', data.payload.cpm);
        this.payload.cpm = parseFloat(data.payload.cpm);
        boolean('PrebidEvent', 'payload.won', data.payload.won);
        this.payload.won = typeof data.payload.won === 'string' ? data.payload.won === 'true' : data.payload.won;
        integer('PrebidEvent', 'payload.timeToRespond', data.payload.timeToRespond);
        this.payload.timeToRespond = parseInt(data.payload.timeToRespond, 10);
        string('PrebidEvent', 'payload.type', data.payload.type);
        hasValue('PrebidEvent', 'payload.type', 'prebid', data.payload.type);
        this.payload.type = data.payload.type;
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
