/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, oneOf, integer, boolean } from '../validators.js';

export class AdslotEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('AdslotEvent', 'schemaName', data.schemaName);
                hasValue('AdslotEvent', 'schemaName', 'event-adslot', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-adslot';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('AdslotEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('AdslotEvent', 'payload.type', data.payload.type);
        hasValue('AdslotEvent', 'payload.type', 'adslot', data.payload.type);
        this.payload.type = data.payload.type;
        string('AdslotEvent', 'payload.adpType', data.payload.adpType);
        oneOf('AdslotEvent', 'payload.adpType', ['available', 'request', 'measurable', 'viewable'], data.payload.adpType);
        this.payload.adpType = data.payload.adpType;
        string('AdslotEvent', 'payload.slotId', data.payload.slotId);
        this.payload.slotId = data.payload.slotId;
        try {
            if (data.payload.adUnit !== undefined) {
                string('AdslotEvent', 'payload.adUnit', data.payload.adUnit);
                this.payload.adUnit = data.payload.adUnit;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.position !== undefined) {
                integer('AdslotEvent', 'payload.position', data.payload.position);
                this.payload.position = parseInt(data.payload.position, 10);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.refreshSeq !== undefined) {
                integer('AdslotEvent', 'payload.refreshSeq', data.payload.refreshSeq);
                this.payload.refreshSeq = parseInt(data.payload.refreshSeq, 10);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.isEmpty !== undefined) {
                boolean('AdslotEvent', 'payload.isEmpty', data.payload.isEmpty);
                this.payload.isEmpty = typeof data.payload.isEmpty === 'string' ? data.payload.isEmpty === 'true' : data.payload.isEmpty;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.advertiserId !== undefined) {
                string('AdslotEvent', 'payload.advertiserId', data.payload.advertiserId);
                this.payload.advertiserId = data.payload.advertiserId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.orderId !== undefined) {
                string('AdslotEvent', 'payload.orderId', data.payload.orderId);
                this.payload.orderId = data.payload.orderId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.lineitemId !== undefined) {
                string('AdslotEvent', 'payload.lineitemId', data.payload.lineitemId);
                this.payload.lineitemId = data.payload.lineitemId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.creativeId !== undefined) {
                string('AdslotEvent', 'payload.creativeId', data.payload.creativeId);
                this.payload.creativeId = data.payload.creativeId;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.size !== undefined) {
                string('AdslotEvent', 'payload.size', data.payload.size);
                this.payload.size = data.payload.size;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
