import { getEnvironment } from './get-environment'

export const getBackendUrl = () => {
    const environment = getEnvironment();
    if (environment === 'localhost') {
        return 'https://services.api.no/api/adplogger/v3/dummy';
    }
    if (environment === 'snap') {
        return 'https://collect-test.adplogger.no/';
    }
    return 'https://collect.adplogger.no/';
}