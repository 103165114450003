import { MetaNode } from "../../src/shared/logger";
import { ADPUnionMemberEvent, ADPUnionMemberMeta } from "../logger";
import { InitOptions } from "../options";

export type MetaNodeType = typeof MetaNode;
// Store function names. Used when user sends a dispatch method
export enum Action {
  "setStartedDomLogging" = "setStartedDomLogging",
  "setRoot" = "setRoot",
  "setInitOptions" = "setInitOptions",
  "addClickEvent" = "addClickEvent",
  "addCommentEvent" = "addCommentEvent",
  "addCustomEvent" = "addCustomEvent",
  "addErrorEvent" = "addErrorEvent",
  "addInscreenEvent" = "addInscreenEvent",
  "addLegacyEvent" = "addLegacyEvent",
  "addVideoEvent" = "addVideoEvent",
  "addPrebidEvent" = "addPrebidEvent",
  "addScrollEvent" = "addScrollEvent",
  "addPlaybackAdEvent" = "addPlaybackAdEvent",
  "addWPAdBlock" = "addWPAdBlock",
  "addArticle" = "addArticle",
  "addCollectionPage" = "addCollectionPage",
  "addCustomElement" = "addCustomElement",
  "addMetaElement" = "addMetaElement",
  "addResource" = "addResource",
  "addTeaser" = "addTeaser",
  "addVideo" = "addVideo",
  "addPage" = "addPage",
  "addMetaEmbed" = "addMetaEmbed",
  "addPerformanceEvent" = "addPerformanceEvent",
  "addWebVitalsEvent" = "addWebVitalsEvent",
  "addAdslotEvent" = "addAdslotEvent",
  "addCallToAction" = "addCallToAction",
  "setDryRun" = "setDryRun",
}

export const events = [
  "ClickEvent",
  "CommentEvent",
  "CustomEvent",
  "ErrorEvent",
  "InscreenEvent",
  "LegacyEvent",
  "VideoEvent",
  "PrebidEvent",
  "ScrollEvent",
  "PlaybackAdEvent",
  "PerformanceEvent",
  "WebVitalsEvent",
  "AdslotEvent",
] as const;

export const meta = [
  "WPAdBlock",
  "Article",
  "CollectionPage",
  "CustomElement",
  "MetaElement",
  "MetaEmbed",
  "Page",
  "Resource",
  "Teaser",
  "Video",
  "CallToAction",
] as const;

export const utils = [
  "startedDomLogging",
  "metaNodeAdded",
  "root",
  "initOptions",
  "dryRun",
] as const;

export type ActionKey = keyof typeof Action;
export type Events = (typeof events)[number];
export type Meta = (typeof meta)[number];
export type Utils = (typeof utils)[number];
export type StateUnion = `${Events | Meta}s` | Utils;
export type State = {
  [key in StateUnion]?: key extends `${ADPUnionMemberMeta}s`
    ? Array<key>
    : key extends `${ADPUnionMemberEvent}s`
    ? Array<key>
    : key extends "startedDomLogging"
    ? boolean
    : key extends "metaNodeAdded"
    ? MetaNodeType
    : key extends "root"
    ? any
    : key extends "initOptions"
    ? InitOptions
    : key extends "dryRun"
    ? boolean
    : never;
};
