/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

import { optionalFieldsErrorHandler } from '../error-handler.js';

export class Definitions {
    constructor(data) {
        let optionalFieldsErrors = []
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
