import { getEnvironment } from "../../../core/get-environment";
import { logError } from "../../../core/events/error/error";
import { version } from "../../../version";

export function optionalFieldsErrorHandler(errors) {
  errors.forEach((error) => {
    if (getEnvironment() === "localhost" || getEnvironment() === "snap") {
      console.warn(
        `V:${version}. Adplogger failed to validate field: ${error}`
      );
    } else {
      logError(
        {
          type: "error",
          message: `V:${version}. ${error}`,
          level: "warning",
        },
        null
      );
    }
  });
}
