/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, number } from '../validators.js';

export class WebVitalsEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('WebVitalsEvent', 'schemaName', data.schemaName);
                hasValue('WebVitalsEvent', 'schemaName', 'event-web-vitals', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-web-vitals';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('WebVitalsEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('WebVitalsEvent', 'payload.type', data.payload.type);
        hasValue('WebVitalsEvent', 'payload.type', 'web-vitals', data.payload.type);
        this.payload.type = data.payload.type;
        try {
            if (data.payload.lcp !== undefined) {
                number('WebVitalsEvent', 'payload.lcp', data.payload.lcp);
                this.payload.lcp = parseFloat(data.payload.lcp);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.fcp !== undefined) {
                number('WebVitalsEvent', 'payload.fcp', data.payload.fcp);
                this.payload.fcp = parseFloat(data.payload.fcp);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.cls !== undefined) {
                number('WebVitalsEvent', 'payload.cls', data.payload.cls);
                this.payload.cls = parseFloat(data.payload.cls);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.ttfb !== undefined) {
                number('WebVitalsEvent', 'payload.ttfb', data.payload.ttfb);
                this.payload.ttfb = parseFloat(data.payload.ttfb);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
