/**
 * Flowplayer video logging
 * @module lib/events/video
 * @typicalname video
 */
import { logError } from "./error/error";
import {
  CustomFlowPlayer,
  CustomFlowPlayerAds,
} from "../../../declarations/flow-player";
import { throttle } from "../throttle";
import { version } from "../../version";
import { createError } from "../../shared/utils";
import { MetaNode, log, addMetaElement } from "../../shared/logger";

const adEvts = [
  "ad-started",
  "ad-skipped",
  "ad-completed",
] as Array<CustomFlowPlayerAds.Events>;
const playerEvents = [
  "cuepointstart",
  "cuepointend",
  "pause",
  "ended",
  "fullscreenenter",
  "fullscreenexit",
] as Array<CustomFlowPlayer.PlayerEvents>;
const playerStartEvents = [
  "loadstart",
  "loadeddata",
] as Array<CustomFlowPlayer.PlayerEvents>;

//TODO: Refactor this
/**
 * Register a player object and attaches logging to the player
 * @param {Object} player A Flowplayer player object
 */
export function registerVideo(
  player: CustomFlowPlayer.Player,
  flowplayer?: CustomFlowPlayer.Flowplayer
) {
  const playerElement = player.root;
  let playerMetaElement: MetaNode;
  let playerTick: number;
  let playerAdTick: number;

  let lastTimestamp = 0;
  let lastCurrentTime = 0;
  let startTime = 0;
  let playing = false;

  let metadataloaded = false;
  let queue = [];

  let adDuration = 0;
  let adProgress = 0;

  function loadMetaData(player: CustomFlowPlayer.Player) {
    playerMetaElement = addMetaElement(playerElement, "Video", {
      title: player.opts.title,
      autoplay:
        player.opts.autoplay === true ||
        player.opts.autoplay === "flowplayer.autoplay.ON",
      autopause: player.opts.autopause,
      live: player.opts.live,
      length: player.duration || 0,
      startTime: player.currentTime,
      src: player.currentSrc,
      adpType: "video",
      type: "Video",
      description: player.opts.description || "",
      contentId: "flowplayer:" + player.opts.metadata?.media_id || "",
      playerId: player.opts.metadata?.player_id || "",
      category: player.opts.metadata?.category_name || "",
    });
    metadataloaded = true;
    queue?.forEach((data) => send(data));
    queue = null;
  }

  function resetPlayerTick(isAd = false) {
    if (isAd) {
      playerAdTick = new Date().getTime(); // used to calculate actual ad duration
    } else {
      playerTick = new Date().getTime(); // used to calculate actual duration
    }
  }

  function getDuration(isAd = false) {
    let duration = 0;
    switch (isAd) {
      case true:
        if (!playerAdTick) {
          break;
        }
        duration = Date.now() - playerAdTick;
        resetPlayerTick(true);
        break;

      default:
        if (!playerTick) {
          break;
        }
        if (player.currentTime === 0) {
          resetPlayerTick();
          break;
        }
        duration = Date.now() - playerTick;
        resetPlayerTick();
        break;
    }
    return duration;
  }

  function send(data) {
    window.dispatchEvent(new CustomEvent("video-active"));
    data.type = "video";
    if (metadataloaded === false) {
      queue.push(data);
      return;
    }
    log<"VideoEvent">("VideoEvent", data, playerMetaElement).catch((e) =>
      console.error(`V:${version}. ${e}`)
    );
  }
  if (
    player.opts &&
    player.playerState &&
    player.playerState["is-loaded"] === true &&
    player.opts.metadata
  ) {
    loadMetaData(player);
  }

  // The media's metadata has finished loading; all attributes
  // now contain as much useful information as they're going to.
  // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
  player.on("loadedmetadata", () => {
    if (!metadataloaded) {
      loadMetaData(player);
    }
    startTime = Math.floor(player.currentTime * 1000);
  });

  player.on(
    "timeupdate",
    throttle((e) => {
      if (e.timeStamp - lastTimestamp > 5000) {
        lastTimestamp = e.timeStamp;
        send({
          adpType: e.type,
          duration: getDuration(),
          currentTime: Math.floor(player.currentTime * 1000),
          playbackRate: player.playbackRate,
        });
      }
    })
  );

  player.on("src", () => {
    loadMetaData(player);
  });

  // note that pause events occur not only when user clicks pause.
  // before buffering, before ads, before finish etc there is also a pause
  playerEvents.forEach((evt) => {
    player.on(evt, (e) => {
      send({
        adpType: e.type,
        duration: getDuration(),
        currentTime: Math.floor(player.currentTime * 1000),
      });
      if (e.type === "pause" || e.type === "ended") {
        // cuepointend ?
        playing = false;
        playerTick = 0;
      }
    });
  });

  // 1: timeSinceLastSeek > 500 stops Flowplayer from flooding the logger with events
  // if the user slides the timemarker on the timeline.
  player.on("seeking", (e) => {
    let timeSinceLastSeek = e.timeStamp - lastTimestamp;
    let durationBeforeSeek = getDuration();
    let currentTimeBeforeSeek =
      (lastCurrentTime || startTime) + durationBeforeSeek;
    if (timeSinceLastSeek > 500) {
      send({
        adpType: e.type,
        duration: durationBeforeSeek,
        currentTime: currentTimeBeforeSeek,
      });
      lastTimestamp = e.timeStamp;
    }
  });

  playerStartEvents.forEach((evt) => {
    player.on(evt, (e) => {
      send({
        adpType: e.type,
      });
      resetPlayerTick();
    });
  });

  // Sent when the video begins to play either for the first time,
  // after having been paused, or after ending and then restarting.
  // we need to control a custom player flag because playing
  // triggers after seeking when player is running. We want to avoid this
  // behavior as it floods the logger
  player.on("playing", (e) => {
    if (!playing) {
      lastCurrentTime = Math.floor(player.currentTime * 1000);
      lastTimestamp = e.timeStamp; // prevent time_update from triggering
      resetPlayerTick();
      send({
        adpType: "playing",
        currentTime: Math.floor(player.currentTime * 1000),
      });
      playing = true;
    }
  });

  player.on("error", (e) => {
    const error = createError(e?.toString());
    logError(error, playerElement);
  });

  // Track ad events if ads exist on the player
  if (flowplayer && flowplayer.ads) {
    // ad paused
    player.on("ad-paused", (e) => {
      log<"PlaybackAdEvent">(
        "PlaybackAdEvent",
        {
          type: "video-ad",
          adpType: e.type,
          // @ts-ignore
          adId: e.detail.vast_ad_id,
          // @ts-ignore
          adType: e.detail.ad_type,
          currentTime: adProgress,
          duration: getDuration(true),
          length: Math.abs(adDuration * 1000),
        },
        playerMetaElement
      ).catch((e) => console.error(`V:${version}. ${e}`));
      playerAdTick = 0;
    });
    // ad resumed
    player.on("ad-resumed", (e) => {
      resetPlayerTick(true);
      log<"PlaybackAdEvent">(
        "PlaybackAdEvent",
        {
          type: "video-ad",
          adpType: e.type,
          // @ts-ignore
          adId: e.detail.vast_ad_id,
          // @ts-ignore
          adType: e.detail.ad_type,
          currentTime: adProgress,
          length: Math.abs(adDuration * 1000),
          duration: getDuration(true),
        },
        playerMetaElement
      ).catch((e) => console.error(`V:${version}. ${e}`));
    });
    // ad progress
    player.on("ad-progress", (e) => {
      // @ts-ignore
      if (typeof e.detail?.duration && typeof e.detail?.remaining) {
        // @ts-ignore
        adProgress = Math.round(
          // @ts-ignore
          (e.detail.duration - e.detail.remaining) * 1000
        );
      }
    });
    // ad started, skipped, completed
    adEvts.forEach((evt) => {
      player.on(evt, (e) => {
        // @ts-ignore
        if (typeof e.detail.ad?.duration) {
          // @ts-ignore
          adDuration = e.detail.ad.duration;
          // @ts-ignore
          lastTimestamp = Math.floor(e.detail.ad.duration * 1000);
        }
        if (e.type === "ad-started") {
          resetPlayerTick(true);
        }
        log<"PlaybackAdEvent">(
          "PlaybackAdEvent",
          {
            type: "video-ad",
            adpType: e.type,
            // @ts-ignore
            adId: e.detail.vast_ad_id,
            // @ts-ignore
            adType: e.detail.ad_type,
            currentTime: e.type === "ad-started" ? 0 : adProgress,
            duration: getDuration(true),
            length: Math.abs(adDuration * 1000),
          },
          playerMetaElement
        ).catch((e) => console.error(`V:${version}. ${e}`));
      });
    });
  }
}
