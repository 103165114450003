/**
 * Based on https://www.stacktracejs.com/
 * @module /lib/events/error/stackframe
 * @typicalname stackframe
 */

function _capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
}

function _getter(p) {
    return function () {
        return this[p];
    };
}

const booleanProps = ['isConstructor', 'isEval', 'isNative', 'isToplevel'];
const numericProps = ['columnNumber', 'lineNumber'];
const stringProps = ['fileName', 'functionName', 'source'];

const stackframeProperties = [...booleanProps, ...numericProps, ...stringProps];

/**
 * Class representing a StackFrame
 * @class StackFrame
 * @property {boolean} [isConstructor] 
 * @property {boolean} [isEval]
 * @property {boolean} [isNative]
 * @property {boolean} [isToplevel]
 * @property {number} [columnNumber]
 * @property {number} [lineNumber]
 * @property {string} [fileName]
 * @property {string} [functionName]
 * @property {string} [source]
 */
export class StackFrame {
    /**
     * @param {object} obj An object of properties to set on the StackFrame object
     */
    constructor(obj: object = {}) {
        stackframeProperties
            .filter(prop => typeof obj[prop] !== 'undefined')
            .forEach(prop => this[`set${_capitalize(prop)}`](obj[prop]));
    }
}

function createGetterAndSetter(props, setter) {
    props.forEach((prop) => {
        const name = _capitalize(prop);
        StackFrame.prototype[`get${name}`] = _getter(prop);
        StackFrame.prototype[`set${name}`] = setter(prop);
    });
}

createGetterAndSetter(booleanProps, p =>
    function (v) {
        this[p] = Boolean(v);
    });

createGetterAndSetter(numericProps, p =>
    function (v) {
        if (!isNaN(parseFloat(v)) && isFinite(v)) {
            this[p] = Number(v);
        }
    });

createGetterAndSetter(stringProps, p =>
    function (v) {
        this[p] = String(v);
    });

