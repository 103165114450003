/**
 * @module lib/logger
 * @typicalname logger
 */

import { getCommon as originalGetCommon } from "./get-common-data";
import { getEventData } from "./get-event-data";
import { getStore as originalGetStore } from "./store/store";
import { v4 as uuidv4 } from "uuid";
import { ADPUnion } from "../../../../declarations/adp-types/adp-union";
import * as types from "./adp-classes/index.js";
import { MetaNode, getClosestMetaElement, getRoot } from "./meta-tree";
import { setCommonMeta } from "./set-common-meta-data";
import {
  ADPUnionMember,
  RequireADPTypeOpt,
} from "../../../../declarations/logger";
import { version } from "../../../version";
import { storePromise } from "../../state/store/promise";

let getStore = originalGetStore;
let getCommon = originalGetCommon;

export function mock(what) {
  getStore = what.getStore || originalGetStore;
  getCommon = what.getCommon || originalGetCommon;
  return { restore: () => mock({}) };
}

// TODO move this to store
export function notifyUpdate(element: any) {
  self.dispatchEvent(
    new CustomEvent("adplogger:store-update", { detail: [element] })
  );
}

// Adds event to localstorage as adp:events
function add(
  storename: string,
  method: string,
  logObjectId: string,
  event: any
) {
  return getStore(storename, "readwrite").then((store) => {
    store[method](event, logObjectId).then(() => store.close());
  });
}

const addFinishedEvent = add.bind(null, "events", "add");

function logCommon<U extends ADPUnionMember = null>(
  type: RequireADPTypeOpt<"type", U>,
  data: RequireADPTypeOpt<"payload", U>,
  element: MetaNode
): Promise<any> {
  return getCommon().then(async (pageview) => {
    const payload = { ...pageview, payload: { ...data } };
    let adpEvent: ADPUnion;
    try {
      adpEvent = new types[type](payload);
    } catch (e) {
      if (e) {
        console.error(`V:${version}. ${e}`);
      }
      console.error(
        `V:${version}. adp log common event error ${type} ${JSON.stringify(
          data
        )}`
      );
      return Promise.reject();
    }
    const store = await storePromise();
    //@ts-ignore
    store.dispatch(`add${type}`, { type, data, pageview, element });
    const logObjectId = uuidv4();
    return addFinishedEvent(logObjectId, adpEvent).then(() =>
      notifyUpdate(element)
    );
  });
}

export async function logMeta(metaElement: MetaNode) {
  if (metaElement.isLogged) {
    return Promise.resolve();
  }
  setCommonMeta(metaElement);
  metaElement.isLogged = true;
  try {
    const store = await storePromise();
    store.dispatch("addMetaElement", { element: metaElement });
    return await logCommon<ADPUnionMember>(
      (metaElement.schemaName ?? metaElement.type) as ADPUnionMember,
      metaElement.data,
      metaElement
    );
  } catch (e) {
    console.log("ERROR", e);
    console.error(
      `V:${version}. log meta error ${e} ${metaElement.type} ${JSON.stringify(
        metaElement.data
      )}`
    );
    metaElement.isLogged = false;
  }
}

async function logEvent<U extends ADPUnionMember = null>(
  type: RequireADPTypeOpt<"type", U>,
  data,
  element = null
): Promise<any> {
  if (element) {
    const metaElement = getClosestMetaElement(element);
    metaElement.events.publish(type, data);
    // Log all parent meta elements before logging the event.
    return Promise.all(metaElement.getTree().map(logMeta))
      .then(() => {
        data.concernsMeta = metaElement.data.id;
        if (metaElement.type) {
          data.metaType = metaElement.type;
        }
        if (metaElement.data.contentId) {
          data.contentId = metaElement.data.contentId;
        }
        return logEvent(type, data, null);
      })
      .catch((e) => {
        console.log(type);
        if (e) {
          console.error(`V:${version}. ${e}`, e);
        }
        console.error(
          `V:${version}. adp logEvent error ${type} ${JSON.stringify(data)}`
        );
        return Promise.reject("logEvent error reject");
      });
  }
  return getEventData().then((commonEventData) =>
    logCommon(type, { ...commonEventData, ...data }, element)
  );
}

/**
 * Log Event or MetaElement data to ADP
 * @param {String} type ADP schema type
 * @param {Object} data Data
 * @param {HTMLElement | MetaNode} [element] Element connected to the log event
 */

export function log<U extends ADPUnionMember = null>(
  type: RequireADPTypeOpt<"type", U>,
  data: RequireADPTypeOpt<"payload", U>,
  element = null
) {
  // Should not log if data-adp-nolog is true
  if (typeof window !== "undefined") {
    const noLog = document.querySelector("html").getAttribute("data-adp-nolog");
    if (noLog === "true") {
      console.warn(
        "Adplogger2 is disabled because of data-adp-nolog property in html tag"
      );
      return Promise.reject("LOGGER DISABLED");
    }
  }

  if (!types[type]) {
    return logEvent<"ErrorEvent">(
      "ErrorEvent",
      {
        level: "error",
        message: `"${type}" is not a valid ADP logger type`,
        type: "error",
      },
      getRoot()
    );
  }
  const Type = types[type];
  // Could use isPrototypeOf instead. But eslint complains
  if (Type.prototype instanceof types.AdpEvent) {
    element = element || getRoot()?.element;
    return logEvent(type, data, element);
  }
  if (Type.prototype instanceof types.MetaElement) {
    throw new Error(
      "MetaElement types should not be logged directly. Only as a part of an event"
    );
  }
  // Whatever you are logging, assume they are complete
  return logCommon(type, data, element);
}
