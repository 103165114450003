import { storePromise } from "logger/src/shared/state/store/promise";

import { getEnvironment } from "../get-environment";

export function printDebugInfo() {
  const environment = getEnvironment();
  if (environment === "localhost") {
    const style = [
      "color: #52de73",
      "background: #1D1E20",
      "font-size: 12px",
      "border: 1px solid #52de73",
      "padding: 10px",
    ].join(";");

    console.log(
      `%cADPLogger2 Status: Ready | Environment: ${environment} | Debugmode: False`,
      style
    );

    console.info(
      "%cCheck get-environment.js if you see this message in production.",
      "color:#52de73; padding: 3px 3px 3px 3px; "
    );
  }

  storePromise().then((store) => {
    if (store.state.dryRun) {
      console.log(
        "%cAdplogger SDK is running in Dry Run mode. Events will not be sent to the backend.",
        "display: inline-block ; border: 3px solid red ; border-radius: 7px ; " +
          "padding: 10px ; margin: 20px ;"
      );
    }
  });
}
