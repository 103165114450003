import { CustomFlowPlayer } from "../../../../declarations/flow-player";
import { PubSub } from "../../state/lib/pubsub";
import { storePromise } from "../../state/store/promise";
import {
  getPageUrl,
  attributeExistsInElement,
  getAttribute,
  removePiiFromUrl,
  ClearableWeakMap,
} from "../../utils";
import { logMeta } from "./logger";

import { setCommonMeta } from "./set-common-meta-data";
import { getLastVisitDates, logNonUserAccess } from "./session";

export const test_schema = {
  title: "test",
  version: 0,
  description: "test",
  primaryKey: "test_id",
  type: "object",
  properties: {
    test_id: {
      type: "string",
    },
  },
};

const attributesToGet = [
  { key: "pageModel", attribute: "data-pagemodel" },
  { key: "isFrontPage", attribute: "data-isfrontpage" },
  { key: "sourceApp", attribute: "data-sourceapp" },
];

const DummyMetaNodeData = {
  id: "",
  contentId: "",
  cssSelector: "",
  position: 0,
  parent: "",
};
type MetaNodeData = Partial<typeof DummyMetaNodeData> &
  Partial<CustomFlowPlayer.PlayerOpts>;

export class MetaNode {
  element: Element;
  children: any[];
  parent: any;
  data: MetaNodeData;
  type: string;
  isLogged: boolean = false;
  events: PubSub<"event">;
  schemaName: string | undefined;
  constructor(
    element: Element,
    type = "MetaElement",
    data = null,
    schemaName = undefined
  ) {
    this.element = element;
    this.children = [];
    this.parent = null;
    this.data = data;
    this.type = type;
    this.events = new PubSub();
    this.schemaName = schemaName;
  }

  getTree() {
    const parents = [];
    let current = this;
    while (current) {
      parents.push(current);
      current = current.parent;
    }
    return parents;
  }
}

let root: MetaNode;
let rootHtmlElement: Element;
export let lookup = new ClearableWeakMap();

async function logPage(rootElement: Element, log) {
  const pageKeys: any = {};
  const store = await storePromise();
  pageKeys.type = "Page";
  pageKeys.pageUrl = getPageUrl();
  pageKeys.pageUrl = removePiiFromUrl(getPageUrl());

  const lastVisitDates = await getLastVisitDates();
  if (lastVisitDates.length >= 2) {
    pageKeys.lastDomainVisitDate = new Date(lastVisitDates[1]).toISOString();
  }

  if (self.document && self.document.title) {
    pageKeys.name = self.document.title;
  }
  if (
    typeof self.document.referrer === "string" &&
    self.document.referrer.length > 0
  ) {
    pageKeys.referrer = removePiiFromUrl(self.document.referrer);
  }

  attributesToGet.forEach(
    ({ attribute, key }) =>
      // If we have html attributes, we use them
      (attributeExistsInElement(attribute) &&
        (pageKeys[key] = getAttribute(attribute))) ||
      // If not we use config from store
      //@ts-ignore
      (store.state.initOptions?.config &&
        //@ts-ignore
        store.state.initOptions?.config![key] &&
        //@ts-ignore
        (pageKeys[key] = store.state.initOptions?.config[key]))
  );

  const root = new MetaNode(rootElement, "Page", { ...pageKeys });
  if (log) {
    setTimeout(() => logMeta(root), 0);
  } else {
    setCommonMeta(root);
    root.isLogged = true;
  }

  rootHtmlElement = rootElement;
  return root;
}

export async function resetMetaTree(
  log = true,
  call?: () => void,
  page = null
) {
  const rootElement =
    self.document && self.document.documentElement
      ? document.documentElement
      : self;
  root = await logPage(rootElement as Element, log);
  lookup.clear();
  const store = await storePromise();
  store.dispatch("setRoot", root);
  lookup.set(rootElement, root);
  window.dispatchEvent(
    new CustomEvent("adplogger:store-init", { detail: store })
  );
  logNonUserAccess();

  //Set adplogger global to ready
  globalThis.Adplogger2.ready = true;

  if (call) call();
}

function findParent(element) {
  return (function recurse(currentNode) {
    if (!currentNode?.element?.contains(element)) {
      return false;
    }
    let result = currentNode;
    currentNode.children.every((child) => {
      const temp = recurse(child);
      if (temp) {
        result = temp;
        return false;
      }
      return true;
    });
    return result;
  })(root);
}

function isHTMLElement(element) {
  return self.HTMLElement && element instanceof HTMLElement;
}

export function getMetaElement(element) {
  return lookup.get(element) || null;
}

export function getRoot() {
  return root;
}

export function getRootHtmlElement() {
  return rootHtmlElement;
}

export function getClosestMetaElement(
  element: (EventTarget & Element) | ParentNode
): null | MetaNode {
  if (element instanceof MetaNode) {
    return element;
  }
  let metaElement = null;
  while (element !== null && metaElement === null) {
    metaElement = getMetaElement(element);
    element = element.parentNode || null;
  }

  return metaElement || getRoot();
}

//Only for video.
//We need something else then weakmap becuase we can have multiple videos on same element
const videoElements = [];

export function addMetaElement(
  element: Element,
  type: string,
  data: MetaNodeData,
  override = false,
  schemaName = undefined
) {
  if (!element) {
    throw new Error("You must provide an Element");
  }
  let node = lookup.get(element);

  if (type === "Video") {
    const elmToSave = {
      contentId: data.contentId,
      element: element,
    };
    const elementExists = videoElements.find(
      (el) => el.contentId === data.contentId
    );
    if (!elementExists) {
      videoElements.push(elmToSave);
      node = null;
    } else {
      node = elementExists.node;
    }
  }

  if (node) {
    if (override) node.isLogged = false;

    const id = node.data.id;
    node.data = { ...node.data, ...data };
    if (!data.id && id) node.data.id = id;
    return node;
  }

  node = new MetaNode(element, type, data, schemaName);
  if (type === "Video") {
    const elementIndex = videoElements.findIndex(
      (el) => el.contentId === data.contentId
    );
    if (elementIndex !== -1) {
      videoElements[elementIndex].node = node;
    }
  }
  lookup.set(element, node);
  if (!isHTMLElement(element)) {
    node.parent = root;
    return node;
  }
  // Add DOM parent
  const parent = findParent(element);
  if (!parent) {
    return node;
  }

  node.parent = parent;
  parent.children = parent.children.filter((parentChildNode) => {
    if (element.contains(parentChildNode.element)) {
      node.children.push(parentChildNode);
      return false;
    }
    return true;
  });
  parent.children.push(node);
  return node;
}

// Starts the app. Kind like a main()
// Useful place to set a breakpoint in your
// JS debugger if you want to see the flow of
// the app from start to end

// Should not log if data-adp-nolog is true
if (typeof window !== "undefined") {
  const noLog = document.querySelector("html").getAttribute("data-adp-nolog");
  if (noLog !== "true") {
    /*  resetMetaTree(); */
  } else {
    console.warn(
      "Adplogger2 is disabled because of data-adp-nolog property in html tag"
    );
  }
}

/* ready(resetMetaTree) */
