/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, uri, dateTime, boolean, integer, oneOf } from '../validators.js';

export class Page extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('Page', 'schemaName', data.schemaName);
                hasValue('Page', 'schemaName', 'meta-page', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-page';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('Page', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('Page', 'payload.type', data.payload.type);
                hasValue('Page', 'payload.type', 'Page', data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'WebPageElement';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.name !== undefined) {
                string('Page', 'payload.name', data.payload.name);
                this.payload.name = data.payload.name;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.referrer !== undefined) {
                string('Page', 'payload.referrer', data.payload.referrer);
                uri('Page', 'payload.referrer', data.payload.referrer);
                this.payload.referrer = data.payload.referrer;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.previousIdentifier !== undefined) {
                string('Page', 'payload.previousIdentifier', data.payload.previousIdentifier);
                this.payload.previousIdentifier = data.payload.previousIdentifier;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.lastDomainVisitDate !== undefined) {
                string('Page', 'payload.lastDomainVisitDate', data.payload.lastDomainVisitDate);
                dateTime('Page', 'payload.lastDomainVisitDate', data.payload.lastDomainVisitDate);
                this.payload.lastDomainVisitDate = data.payload.lastDomainVisitDate;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.sourceApp !== undefined) {
                string('Page', 'payload.sourceApp', data.payload.sourceApp);
                this.payload.sourceApp = data.payload.sourceApp;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adblockDetected !== undefined) {
                boolean('Page', 'payload.adblockDetected', data.payload.adblockDetected);
                this.payload.adblockDetected = typeof data.payload.adblockDetected === 'string' ? data.payload.adblockDetected === 'true' : data.payload.adblockDetected;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.initialDocumentHeight !== undefined) {
                integer('Page', 'payload.initialDocumentHeight', data.payload.initialDocumentHeight);
                this.payload.initialDocumentHeight = parseInt(data.payload.initialDocumentHeight, 10);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.pageUrl !== undefined) {
                string('Page', 'payload.pageUrl', data.payload.pageUrl);
                uri('Page', 'payload.pageUrl', data.payload.pageUrl);
                this.payload.pageUrl = data.payload.pageUrl;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.pageModel !== undefined) {
                string('Page', 'payload.pageModel', data.payload.pageModel);
                this.payload.pageModel = data.payload.pageModel;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.isFrontPage !== undefined) {
                string('Page', 'payload.isFrontPage', data.payload.isFrontPage);
                oneOf('Page', 'payload.isFrontPage', ['true', 'false'], data.payload.isFrontPage);
                this.payload.isFrontPage = data.payload.isFrontPage;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        Object.keys(data.payload).filter(key => /^ns_.*/.test(key)).forEach((key) => {
            const temp = data.payload[key];
            string('Page', 'temp', temp);
            this.payload[key] = temp;
        });
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
