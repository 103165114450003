/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { MetaElement } from './meta-element.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, integer } from '../validators.js';

export class WPAdBlock extends MetaElement {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('WPAdBlock', 'schemaName', data.schemaName);
                hasValue('WPAdBlock', 'schemaName', 'meta-ad', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'meta-ad';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('WPAdBlock', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.payload.type !== undefined) {
                string('WPAdBlock', 'payload.type', data.payload.type);
                hasValue('WPAdBlock', 'payload.type', 'WPAdBlock', data.payload.type);
                this.payload.type = data.payload.type;
            } else if (!isExtended) {
                this.payload.type = 'WPAdBlock';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.adpType !== undefined) {
                string('WPAdBlock', 'payload.adpType', data.payload.adpType);
                hasValue('WPAdBlock', 'payload.adpType', 'ad', data.payload.adpType);
                this.payload.adpType = data.payload.adpType;
            } else if (!isExtended) {
                this.payload.adpType = 'ad';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('WPAdBlock', 'payload.name', data.payload.name);
        this.payload.name = data.payload.name;
        try {
            if (data.payload.position !== undefined) {
                integer('WPAdBlock', 'payload.position', data.payload.position);
                this.payload.position = parseInt(data.payload.position, 10);
            } else if (!isExtended) {
                this.payload.position = 0;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('WPAdBlock', 'payload.unit', data.payload.unit);
        this.payload.unit = data.payload.unit;
        string('WPAdBlock', 'payload.lineItem', data.payload.lineItem);
        this.payload.lineItem = data.payload.lineItem;
        string('WPAdBlock', 'payload.advertiser', data.payload.advertiser);
        this.payload.advertiser = data.payload.advertiser;
        string('WPAdBlock', 'payload.creative', data.payload.creative);
        this.payload.creative = data.payload.creative;
        try {
            if (data.payload.order !== undefined) {
                string('WPAdBlock', 'payload.order', data.payload.order);
                this.payload.order = data.payload.order;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
