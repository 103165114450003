import { storePromise } from "logger/src/shared/state/store/promise";
import { isLocalhost } from "../get-environment";

const KILKAYA_URL = "//cl.k5a.io/6143532e3fb9a76297593ea2.js";
const MBL_URL = "//log.medietall.no/analytics.js";

const mblProps = ["paid", "login", "subscriber", "channel", "paywall", "subscriberid"];
const k5aMetaProps = [
  "paid",
  "login",
  "subscriber",
  "channel",
  "paywall",
  "subscriberid",
  "cntTag",
];

const k5aMeta = {};
const mblMeta = {};

const assignToWindow = () => {
  // @ts-ignore
  window.k5aMeta = k5aMeta;
  // @ts-ignore
  window.mblMeta = mblMeta;
};

const assignPropToGlobalVar = (prop, value) => {
  if (mblProps.indexOf(prop) > -1) mblMeta[prop] = value;
  if (k5aMetaProps.indexOf(prop) > -1) k5aMeta[prop] = value;
};
const blacklistedHosts = [];
export async function getUserKey(): Promise<boolean> {
  const store = await storePromise();
  const useUserModuleModule =
    !store.state.initOptions?.exclude?.modules?.includes("user");
  if (!useUserModuleModule) return;
  if (
    typeof window !== "undefined" &&
    blacklistedHosts.indexOf(window.location.host) > -1
  ) {
    assignPropToGlobalVar("login", false);
    assignPropToGlobalVar("subscriber", false);
    return Promise.resolve(null);
  }
  return new Promise(async (resolve, reject) => {
    const { isPaywall, hasDataAccess } = getHeadData();
    assignPropToGlobalVar("paid", isPaywall);
    if (isPaywall && !hasDataAccess) assignPropToGlobalVar("paywall", "hard");
    try {
      const { UserDataRequest } = await import("@amedia/user");
      return new UserDataRequest()
        .withAttributes(["trackingKey", "extraData"])
        .fetch({
          timeout: isLocalhost() ? 1 : 500,
        })
        .then(({ attributes, state }) => {
          const isSubscriber = attributes.extraData?.customer_keys;
          const subscriberid = attributes.extraData?.mbl_user_key;
          const gender = attributes.extraData?.gender
            ? attributes.extraData?.gender === "F"
              ? "female"
              : "male"
            : "";
          const agegroup = attributes.extraData?.age
            ? Number(attributes.extraData?.age) >= 40
              ? "above40"
              : "below40"
            : "";
          const cntTag = [
            ...(agegroup ? [agegroup] : []),
            ...(gender ? [gender] : []),
          ];

          assignPropToGlobalVar("login", state.isLoggedIn);
          assignPropToGlobalVar(
            "subscriber",
            typeof isSubscriber !== "undefined"
          );
          if (typeof subscriberid !== "undefined") {
            assignPropToGlobalVar("subscriberid", subscriberid);
          }
          if (cntTag.length > 0) {
            assignPropToGlobalVar("cntTag", cntTag);
          }
          resolve(true);
        })
        .catch(() => {
          assignPropToGlobalVar("login", false);
          assignPropToGlobalVar("subscriber", false);
          reject(false);
        });
    } catch (error) {
      assignPropToGlobalVar("login", false);
      assignPropToGlobalVar("subscriber", false);
      reject(false);
    }
  });
}

const getKilklayaInstance = (): Promise<{ element: HTMLScriptElement }> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = KILKAYA_URL;
    resolve({ element: script });
  });
};

const getMBLInstance = (): Promise<{ element: HTMLScriptElement }> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = MBL_URL;
    resolve({ element: script });
  });
};

const getHeadData = () => {
  const htmlElm = document.getElementsByTagName("html")[0];
  return {
    isPaywall: htmlElm.getAttribute("data-paywall") === "true",
    hasDataAccess: htmlElm.getAttribute("data-access") === "true",
  };
};

export const initKilkaya = async () => {
  const store = await storePromise();
  const useKilkaya =
    !store.state.initOptions?.exclude?.modules?.includes("kilkaya");
  const useMbl = !store.state.initOptions?.exclude?.modules?.includes("mbl");
  const { element: kilkayaElement } = await getKilklayaInstance();
  const { element: mblElement } =
   await getMBLInstance();
  const pushapp =
    navigator.userAgent.indexOf("pushvarslings-app") !== -1 ||
    navigator.userAgent.indexOf("Nettavisen") !== -1;
  if (pushapp) {
    const iPad = navigator.userAgent.indexOf("iPad") !== -1;
    const iPhone = navigator.userAgent.indexOf("iPhone") !== -1;
    const android = navigator.userAgent.indexOf("ndroid") > -1;
    const tabletpattern = /Samsung (SM-T|GT-P|SM-P|SM-N)|SGP|Lenovo|iPad/g;
    const tablet = tabletpattern.test(navigator.userAgent);
    if (iPhone) {
      assignPropToGlobalVar("channel", "mobile_ios_app");
    } else if (iPad) {
      assignPropToGlobalVar("channel", "tablet_ios_app");
    } else if (android && tablet) {
      assignPropToGlobalVar("channel", "tablet_android_app");
    } else if (android) {
      assignPropToGlobalVar("channel", "mobile_android_app");
    }
  }
  try {
    await getUserKey();
    assignToWindow();
    shouldLoadScript(KILKAYA_URL, useKilkaya) &&
      document.head.appendChild(kilkayaElement);
    shouldLoadScript(MBL_URL, useMbl) && document.head.appendChild(mblElement);
  } catch (error) {
    assignToWindow();
    shouldLoadScript(KILKAYA_URL, useKilkaya) &&
      document.head.appendChild(kilkayaElement);
    shouldLoadScript(MBL_URL, useMbl) && document.head.appendChild(mblElement);
  }
};

const shouldLoadScript = (url: string = "", exclude: boolean) => {
  if (!exclude) return false;
  let loadScript = true;
  const scripts = document.getElementsByTagName("script");
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src.search(url) > -1) {
      loadScript = false;
      break;
    }
  }
  return loadScript;
};
