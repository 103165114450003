export default {
  startedDomLogging: false,
  root: null,
  initOptions: [],
  ErrorEvents: [],
  ClickEvents: [],
  CommentEvents: [],
  CustomEvents: [],
  InscreenEvents: [],
  LegacyEvents: [],
  VideoEvents: [],
  PrebidEvents: [],
  ScrollEvents: [],
  PlaybackAdEvents: [],
  AdpEvents: [],
  WPAdBlocks: [],
  Articles: [],
  CollectionPages: [],
  CustomElements: [],
  MetaElements: [],
  Resources: [],
  Teasers: [],
  Videos: [],
  Pages: [],
  MetaEmbeds: [],
  PerformanceEvents: [],
  WebVitalsEvents: [],
  CallToActions: [],
  AdslotEvents: [],
  DryRun: false,
};
