export class ADPTypeError extends TypeError {
    constructor(className, path) {
        super();
        this.name = 'ADPTypeError';
        this.message = `Could not create ${className} instance. "${path}" must be `;
    }
}
export class StringError extends ADPTypeError {
    constructor(className, path, value) {
        super(className, path);
        this.name = 'StringError';
        this.message += `a "String". Value is "${JSON.stringify(value)}"`;
    }
}

export class NumberError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'NumberError';
        this.message += 'a "Float Number"';
    }
}

export class IntegerError extends ADPTypeError {
    constructor(className, path, value) {
        super(className, path);
        this.name = 'IntegerError';
        this.message += `an "Integer". Value is "${JSON.stringify(value)}"`;
    }
}

export class BooleanError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'BooleanError';
        this.message += 'a Boolean"';
    }
}

export class ObjectError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'ObjectError';
        this.message += 'an "Object"';
    }
}

export class ArrayError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'ArrayError';
        this.message += 'an Array';
    }
}

export class MultiError extends ADPTypeError {
    constructor(className, types, path, extra="") {
        super(className, path);
        this.name = 'MultiError';
        this.message += `one of ${types.join()} extra: ${extra}`;
    }
}

export class UriError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'UriError';
        this.message += 'a valid uri';
    }
}

export class DateTimeError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'DateTimeError';
        this.message += 'a valid date-time';
    }
}

export class TimeError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'TimeError';
        this.message += 'a valid time';
    }
}

export class DateError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'DateError';
        this.message += 'a valid date';
    }
}

export class EmailError extends ADPTypeError {
    constructor(className, path) {
        super(className, path);
        this.name = 'EmailError';
        this.message += 'a valid email';
    }
}

export class AllowedValuesError extends ADPTypeError {
    constructor(className, path, allowedValues, currentValue) {
        super(className, path);
        this.name = 'AllowedValuesError';
        this.message += `one of ${allowedValues.join(', ')}. Current value is "${JSON.stringify(currentValue)}"`;
    }
}

export class AllowedValueError extends ADPTypeError {
    constructor(className, path, allowedValue, currentValue) {
        super(className, path);
        this.name = 'AllowedValueError';
        this.message += `'${allowedValue}'. Current Value is '${currentValue}'.`;
    }
}

export class NoIndexedDB extends Error {
    constructor(message = '') {
        super(message);
        this.name = 'NoIndexedDB';
    }
}

export class WrongModeIndexedDB extends Error {
    constructor(message) {
        super(message);
        this.name = 'WrongModeIndexedDB';
    }
}

export class NoLocalStorage extends Error {
    constructor(message = '') {
        super(message);
        this.name = 'NoLocalStorage';
    }
}
