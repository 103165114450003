import { NoLocalStorage } from "../errors";
import { version } from "../../../../version";

import { FakePromise, FakeRejectPromise } from "./fake-promise";

export function localStorageAvailable() {
  let storage: Storage;
  try {
    storage = window.localStorage;
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      // @ts-ignore
      storage &&
      storage.length !== 0
    );
  }
}

function getStorage(name: string, func: (store: Storage) => void) {
  try {
    const storage = JSON.parse(
      window.localStorage.getItem(`adp:${name}`) || "{}"
    );
    const result = func(storage);
    window.localStorage.setItem(`adp:${name}`, JSON.stringify(storage));
    return result;
  } catch (e) {
    if (!localStorageAvailable) {
      console.error("No local storage available.");
    } else {
      console.error(`Failed to get local storage. Error: ${e}`);
    }
    //@ts-ignore
    return new FakeRejectPromise(new NoLocalStorage());
  }
}
export function getStore(name: any) {
  if (!localStorageAvailable) {
    //@ts-ignore
    return new FakeRejectPromise(new NoLocalStorage());
  }
  const get = getStorage.bind(null, name);
  return new FakePromise({
    put: (value: any, key: string | number) =>
      get((store: { [x: string]: any }) => {
        store[key] = value;
        return new FakePromise();
      }),
    del: (key: string | number) =>
      get((store: { [x: string]: any }) => {
        delete store[key];
        return new FakePromise();
      }),
    get: (key: string | number) =>
      get((store: { [x: string]: any }) => new FakePromise(store[key])),
    list: () =>
      get((store: {}) => {
        const keys = Object.keys(store);
        return new FakePromise(keys);
      }),
    add: (value: any, key: string | number) =>
      get((store: { [x: string]: any }) => {
        store[key] = value;
        return new FakePromise();
      }),
    close: () => new FakePromise(),
  });
}

export function flush(name = "events") {
  return getStorage(name, (store) => {
    const result = Object.keys(store).map((key) => {
      const value = store[key];
      delete store[key];
      return value;
    });
    return new FakePromise(result);
  });
}

export function getEvents() {
  return getStorage("events", (store) => {
    const result = Object.keys(store).map((key) => {
      const value = store[key];
      return value;
    });
    return new FakePromise(result);
  });
}
