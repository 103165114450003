/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, object } from '../validators.js';

export class Common {
    constructor(data, isExtended = false) {
        let optionalFieldsErrors = []
        string('Common', 'domainBrowserId', data.domainBrowserId);
        this.domainBrowserId = data.domainBrowserId;
        string('Common', 'domainVisitId', data.domainVisitId);
        this.domainVisitId = data.domainVisitId;
        try {
            if (data.siteKey !== undefined) {
                string('Common', 'siteKey', data.siteKey);
                this.siteKey = data.siteKey;
            } else if (!isExtended) {
                this.siteKey = 'unknown';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('Common', 'pageViewId', data.pageViewId);
        this.pageViewId = data.pageViewId;
        try {
            if (data.userKey !== undefined) {
                string('Common', 'userKey', data.userKey);
                this.userKey = data.userKey;
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        string('Common', 'logger', data.logger);
        this.logger = data.logger;
        object('Common', 'payload', data.payload);
        this.payload = this.payload || {};
        try {
            if (data.schemaName !== undefined) {
                string('Common', 'schemaName', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'common';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
