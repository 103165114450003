/* THIS FILE IS AUTOMATICALLY GENERATED. DO NOT EDIT */

//@ts-nocheck
import { AdpEvent } from './event.js';
import { optionalFieldsErrorHandler } from '../error-handler.js';
import { string, hasValue, object, number } from '../validators.js';

export class PerformanceEvent extends AdpEvent {
    constructor(data, isExtended = false) {
        // @ts-ignore
        super(data, true);
        let optionalFieldsErrors = []
        try {
            if (data.schemaName !== undefined) {
                string('PerformanceEvent', 'schemaName', data.schemaName);
                hasValue('PerformanceEvent', 'schemaName', 'event-performance', data.schemaName);
                this.schemaName = data.schemaName;
            } else if (!isExtended) {
                this.schemaName = 'event-performance';
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        object('PerformanceEvent', 'payload', data.payload);
        this.payload = this.payload || {};
        string('PerformanceEvent', 'payload.type', data.payload.type);
        hasValue('PerformanceEvent', 'payload.type', 'performance', data.payload.type);
        this.payload.type = data.payload.type;
        try {
            if (data.payload.fp !== undefined) {
                number('PerformanceEvent', 'payload.fp', data.payload.fp);
                this.payload.fp = parseFloat(data.payload.fp);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.fcp !== undefined) {
                number('PerformanceEvent', 'payload.fcp', data.payload.fcp);
                this.payload.fcp = parseFloat(data.payload.fcp);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.domInteractive !== undefined) {
                number('PerformanceEvent', 'payload.domInteractive', data.payload.domInteractive);
                this.payload.domInteractive = parseFloat(data.payload.domInteractive);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.domContentLoaded !== undefined) {
                number('PerformanceEvent', 'payload.domContentLoaded', data.payload.domContentLoaded);
                this.payload.domContentLoaded = parseFloat(data.payload.domContentLoaded);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        try {
            if (data.payload.domComplete !== undefined) {
                number('PerformanceEvent', 'payload.domComplete', data.payload.domComplete);
                this.payload.domComplete = parseFloat(data.payload.domComplete);
            }
        } catch (error) {
            optionalFieldsErrors.push(error);
        }
        if (optionalFieldsErrors.length > 0) {optionalFieldsErrorHandler(optionalFieldsErrors)}
    }
}
