import { MetaNode } from "../../logger";

export const getElement = (el: Element | MetaNode | string) => {
    if (el instanceof MetaNode) {
        return (el.element as Element);
    } else if (el instanceof Element) {
        return el;
    } else {
        return document.getElementById(el);
    }
}