import { ActionKey, State } from "../../../../declarations/store/state";
import { PubSub } from "../lib/pubsub";

export default class Store {
  private actions: {};
  private mutations: {};
  private status: string;
  state: State;
  events: PubSub<"meta" | "event" | "util">;
  constructor(params) {
    this.actions = {};
    this.mutations = {};
    this.state = {};
    this.status = "resting";
    this.events = new PubSub();
    if (params.hasOwnProperty("actions")) this.actions = params.actions;
    if (params.hasOwnProperty("mutations")) this.mutations = params.mutations;
    this.state = new Proxy(params.state || {}, {
      set: (state: any, key: string, value: any) => {
        state[key] = value;

        this.status = "resting";

        return true;
      },
    });
  }

  //ACTION
  dispatch(actionKey: ActionKey, payload: any) {
    if (typeof this.actions[actionKey] !== "function") {
      console.error(`Action "${actionKey} doesn't exist.`);
      return false;
    }

    this.status = "action";

    this.actions[actionKey](this, payload);

    return true;
  }

  //MUTATION
  commit(actionKey: ActionKey, payload: any) {
    if (typeof this.mutations[actionKey] !== "function") {
      console.log(`Mutation "${actionKey}" doesn't exist`);
      return false;
    }

    this.status = "mutation";

    const newState = this.mutations[actionKey](this.state, payload);
    try {
      this.state = Object.assign(this.state, newState);
      this.events.publish(
        this.map[actionKey],
        this.state[`${this.map[actionKey]}s`]
      );
      new CustomEvent("adplogger:store-update", { detail: this.events });
      return true;
    } catch (error) {
      return false;
    }
  }

  map = {
    addEvent: "allEvents",
    setStartedDomLogging: "startedDomLogging",
    setPageView: "pageView",
    addClickEvent: "ClickEvent",
    addCommentEvent: "CommentEvent",
    addCustomEvent: "CustomEvent",
    addErrorEvent: "ErrorEvent",
    addInscreenEvent: "InscreenEvent",
    addLegacyEvent: "LegacyEvent",
    addVideoEvent: "VideoEvent",
    addPrebidEvent: "PrebidEvent",
    addScrollEvent: "ScrollEvent",
    addPlaybackAdEvent: "VideoAdEvent",
    addAdslotEvent: "AdslotEvent",
    addAdpEvent: "AdpEvent",
    addWPAdBlock: "WPAdBlock",
    addArticle: "Article",
    addCollectionPage: "CollectionPage",
    addCustomElement: "CustomElement",
    addMetaElement: "MetaElement",
    addResource: "Resource",
    addTeaser: "Teaser",
    addVideo: "Video",
    addPage: "Page",
    addPerformanceEvent: "PerformanceEvent",
    addWebVitalsEvent: "WebVitalsEvent",
    addMetaEmbed: "MetaEmbed",
    addCallToAction: "CallToAction",
    setDryRun: "SetDryRun",
  };
}
