import { v4 as uuidv4 } from "uuid";

import { version } from "../../../version";
import { storePromise } from "../../state/store/promise";

import { getPageViewId, getUserKey } from "./session";

const isLocalHost = location.hostname.includes("localhost");
const localhostBrowserId = uuidv4();
const localhostDomainVisitId = uuidv4();
const localhostPageViewId = uuidv4();
const localhostUserKey = uuidv4();

let siteKey;

export async function getCommon() {
  const store = await storePromise();
  const userKeyPromise = getUserKey().catch(() => null);
  if (!siteKey) {
    siteKey =
      document.querySelector("html")?.getAttribute("data-sitekey") ||
      //@ts-ignore
      store.state.initOptions?.config?.siteKey ||
      "unknown";
  }
  const logger = `adplogger2JS:${version}`;

  const { getBrowserId, getVisitId } = !isLocalHost
    ? await import("@amedia/browserid")
    : { getBrowserId: null, getVisitId: null };

  const [domainBrowserId, domainVisitId, pageViewId, userKey] =
    await Promise.all([
      isLocalHost ? localhostBrowserId : getBrowserId(),
      isLocalHost ? localhostDomainVisitId : getVisitId(),
      isLocalHost ? localhostPageViewId : getPageViewId(),
      isLocalHost ? localhostUserKey : userKeyPromise,
    ]);
  return {
    pageViewId,
    domainBrowserId: domainBrowserId || "",
    domainVisitId: domainVisitId || "",
    ...(userKey && { userKey }),
    siteKey,
    logger,
    payload: {},
  };
}
